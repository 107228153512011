import { Button, Heading, Icon } from "@harmony"

export function PaymentFailed({ onClose }) {
  return (
    <div className="flex flex-col gap-8 text-center mt-6 mb-4">
      <span className="mt-0.5">
        <Icon
          name="XCircle"
          size="48px"
          className="fill-harmony-critical-500 m-auto"
        />
      </span>
      <Heading level={2}>Payment failed</Heading>
      <Button variant="solid" onClick={onClose} isFullWidth>
        Close
      </Button>
    </div>
  )
}
