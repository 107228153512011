import { Typography, Loader, Button } from "@harmony"
import { useNavigateExtended } from "modules/checkout/hooks"
import { useFreedomPay, useSharedFreedomPay } from "services/FreedomPay"
import { IFRAME_TYPES } from "services"
import { Routes } from "modules/checkout/constants"
export function FreedomPay({ config }) {
  const navigate = useNavigateExtended()
  const { onPaymentError, createIntent, confirmIntent } = useSharedFreedomPay()
  const { isLoading, renderIframe } = useFreedomPay({
    config,
    getIframeUrls: () =>
      createIntent([IFRAME_TYPES.GOOGLE_PAY, IFRAME_TYPES.APPLE_PAY]),
    onError: (err) => onPaymentError(err),
    onConfirmation: (data) => confirmIntent(data),
  })

  if (isLoading) {
    return (
      <div className="flex justify-center w-full">
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <div className="flex flex-col justify-center w-full gap-2">
        {renderIframe(IFRAME_TYPES.APPLE_PAY)}
        {renderIframe(IFRAME_TYPES.GOOGLE_PAY)}
      </div>
      <Typography className="text-lg font-bold my-4 text-center">OR</Typography>
      <Button
        variant="outline"
        isFullWidth
        onClick={() => navigate.append(Routes.PaymentCardUserDetailsBill)}
        startIcon="CreditCard"
      >
        Pay by card
      </Button>
    </div>
  )
}
