import { Heading } from "@harmony"

function FadingCircles() {
  return (
    <div className="w-[100px] h-[100px] grid grid-cols-2 grid-rows-2 gap-4 relative mt-0.5 m-auto items-center">
      <div className="rounded-full bg-harmony-icon-base animate-fadeInOut animation-delay-0 w-[24px] h-[24px] m-auto"></div>
      <div className="rounded-full bg-harmony-icon-base animate-fadeInOut animation-delay-200 w-[24px] h-[24px] m-auto"></div>
      <div className="rounded-full bg-harmony-icon-base animate-fadeInOut animation-delay-600 w-[24px] h-[24px] m-auto"></div>
      <div className="rounded-full bg-harmony-icon-base animate-fadeInOut animation-delay-400 w-[24px] h-[24px] m-auto"></div>
      <style>
        {`
          @keyframes fadeInOut {
            0%, 100% { opacity: 0; }
            50% { opacity: 1; }
          }
            
          .animate-fadeInOut  {
            animation: fadeInOut 4s infinite;
          }
            
          .animation-delay-0 {
            animation-delay: 0s;
          }

          .animation-delay-200 {
            animation-delay: 0.4s;
          }

          .animation-delay-400 {
            animation-delay: 0.8s;
          }

          .animation-delay-600 {
            animation-delay: 1.2s;
          }
        `}
      </style>
    </div>
  )
}

export function PaymentProcessing() {
  return (
    <div className="flex flex-col gap-8 text-center mt-6 mb-4">
      <FadingCircles />
      <Heading level={2}>Payment processing...</Heading>
    </div>
  )
}
