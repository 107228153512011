import { Icon } from "@harmony"

export function ToggleIcon({ isOpen }) {
  const classes = ["transition-transform", "duration-200", "ease-in-out"]

  if (isOpen) {
    classes.push("rotate-180")
  }

  return (
    <div className="h-[24px] w-[24px] overflow-hidden" aria-hidden="true">
      <Icon name="CaretDown" className={classes.join(" ")} />
    </div>
  )
}
