module.exports = {
  theme: {
    extend: {
      colors: {
        harmony: {
          info: {
            50: "#eff6ff",
            100: "#dbeafe",
            200: "#bfdbfe",
            300: "#93c5fd",
            400: "#60a5fa",
            500: "#3b82f6",
            600: "#2563eb",
            700: "#1d4ed8",
            800: "#1e40af",
            900: "#1e3a8a",
            950: "#172554",
          },
          neutral: {
            50: "#fafafa",
            100: "#f4f4f5",
            200: "#e4e4e7",
            300: "#d4d4d8",
            400: "#a1a1aa",
            500: "#71717a",
            600: "#52525b",
            700: "#3f3f46",
            800: "#27272a",
            900: "#18181b",
            950: "#09090b",
          },
          caution: {
            50: "#fefce8",
            100: "#fef9c3",
            200: "#fef08a",
            300: "#fde047",
            400: "#facc15",
            500: "#eab308",
            600: "#ca8a04",
            700: "#a16207",
            800: "#854d0e",
            900: "#713f12",
            950: "#422006",
          },
          positive: {
            50: "#f0fdf4",
            100: "#dcfce7",
            200: "#bbf7d0",
            300: "#86efac",
            400: "#4ade80",
            500: "#22c55e",
            600: "#16a34a",
            700: "#15803d",
            800: "#166534",
            900: "#14532d",
            950: "#052e16",
          },
          critical: {
            50: "#fef2f2",
            100: "#fee2e2",
            200: "#fecaca",
            300: "#fca5a5",
            400: "#f87171",
            500: "#ef4444",
            600: "#dc2626",
            700: "#b91c1c",
            800: "#991b1b",
            900: "#7f1d1d",
            950: "#450a0a",
          },
          brand: {
            accent: {
              50: "#faf4ff",
              100: "#f6eaff",
              200: "#e8ccff",
              300: "#d8a8ff",
              400: "#c67fff",
              500: "#b75bff",
              600: "#a533ff",
              700: "#8e00ff",
              800: "#7200cc",
              900: "#550099",
              950: "#390066",
            },
          },
          text: {
            base: {
              DEFAULT: "#27272a",
              dark: "#fafafa",
            },
            subtle: {
              DEFAULT: "#71717a",
              dark: "#d4d4d8",
            },
          },
          icon: {
            base: {
              DEFAULT: "#27272a",
              dark: "#fafafa",
            },
            subtle: {
              DEFAULT: "#a1a1aa",
              dark: "#52525b",
            },
          },
          border: {
            base: {
              DEFAULT: "#d4d4d8",
              dark: "#71717a",
            },
            subtle: {
              DEFAULT: "#e4e4e7",
              dark: "#52525b",
            },
          },
          surface: {
            base: {
              DEFAULT: "#fafafa",
              dark: "#18181b",
            },
            level: {
              1: {
                DEFAULT: "#ffffff",
                dark: "#3f3f46",
              },
            },
            modal: {
              bg: {
                DEFAULT: "#27272abf",
                dark: "#27272abf",
              },
            },
            transparent: {
              DEFAULT: "#fafafa00",
              dark: "#18181b00",
            },
          },
          button: {
            solid: {
              neutral: {
                bg: {
                  DEFAULT: "#27272a",
                  hover: {
                    DEFAULT: "#52525b",
                    dark: "#d4d4d8",
                  },
                  dark: "#fafafa",
                },
                fg: {
                  DEFAULT: "#ffffff",
                  dark: "#27272a",
                },
                border: {
                  DEFAULT: "#27272a",
                  dark: "#fafafa",
                },
              },
            },
            outline: {
              neutral: {
                bg: {
                  DEFAULT: "#ffffff00",
                  hover: {
                    DEFAULT: "#f4f4f5",
                    dark: "#52525b",
                  },
                  dark: "#ffffff00",
                },
                fg: {
                  DEFAULT: "#27272a",
                  dark: "#ffffff",
                },
                border: {
                  DEFAULT: "#27272a",
                  dark: "#ffffff",
                },
              },
            },
            transparent: {
              neutral: {
                fg: {
                  DEFAULT: "#27272a",
                  dark: "#fafafa",
                },
                bg: {
                  DEFAULT: "#ffffff00",
                  hover: {
                    DEFAULT: "#f4f4f5",
                    dark: "#52525b",
                  },
                  dark: "#ffffff00",
                },
                border: {
                  DEFAULT: "#ffffff00",
                  dark: "#ffffff00",
                },
              },
            },
          },
        },
      },
    },
  },
}
