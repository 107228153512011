import PropTypes from "prop-types"

/**
 * Divider component for rendering a horizontal dashed line.
 *
 * @param {Object} props - The props for the Divider component.
 * @param {"base" | "subtle"} [props.tone="base"] - The tone of the divider.
 * @param {string} [props.className] - Additional custom CSS classes for the divider.
 */

export function Divider({ tone = "base", className = "" }) {
  const dividerClasses = [
    "border-t",
    "border-dashed",
    `border-harmony-border-${tone}`,
    `dark:border-harmony-border-${tone}-dark`,
    "w-full",
  ]

  if (className) {
    dividerClasses.push(className)
  }

  return <div className={dividerClasses.join(" ")}></div>
}

Divider.propTypes = {
  className: PropTypes.string,
  tone: PropTypes.oneOf(["base", "subtle"]),
}
