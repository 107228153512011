import {
  Alert,
  Button,
  ButtonIcon,
  Heading,
  Stack,
  Text,
  TextField,
} from "@harmony"

import { Routes } from "../.."
import {
  EVENT_SPLIT_BY,
  EVENT_SPLIT_SET,
  EVENT_SPLIT_SIZE,
  useCheckout,
  useEventTracker,
  useNavigateExtended,
} from "../../hooks"
import { ModalControls } from "../../parts"
import { VisualSplitIndicator } from "./components"
import { useLocation } from "react-router"
import { useState } from "react"

function getValue(value, input) {
  if (input === "+1") {
    return value + 1
  }

  if (input === "-1") {
    return value - 1
  }

  return input
}

export function SplitBill() {
  const { setPaySplitBy, setPaySplitSize, setPayFull, state, onEndFlow } =
    useCheckout()

  const navigate = useNavigateExtended()
  const { onEvent } = useEventTracker()
  const location = useLocation()

  const [hasRemainingSplitChanged, setRemainingSplitChanged] = useState(
    location.state?.hasRemainingSplitChanged ?? false,
  )

  function onSplitByChange(input) {
    const value = getValue(state.splitBy, input)

    if (value < state.splitSize || value <= 0) {
      return
    }

    setPaySplitBy({ splitBy: value })
    onEvent(EVENT_SPLIT_BY, { value })
  }

  function onSplitSizeChange(input) {
    const value = getValue(state.splitSize, input)

    if (value > state.remainingSplits || value <= 0) {
      return
    }

    setPaySplitSize({ splitSize: value })
    onEvent(EVENT_SPLIT_SIZE, { value })
  }

  function onSubmit() {
    onEvent(EVENT_SPLIT_SET, {
      splitBy: state.splitBy,
      splitSize: state.splitSize,
    })
    navigate.append(Routes.AddTip)
  }

  const canSubmit =
    state.splitSize > 0 && state.splitSize <= state.remainingSplits

  const { share } = state
  const { total } = state.total

  const canSplitBill = state.forceSplitBy
  const numberOfPaidGuests = state.forceSplitBy
    ? 0
    : state.splitBy - state.remainingSplits

  return (
    <>
      <ModalControls
        onNavigateBack={() => {
          setPayFull()
          navigate.previous()
        }}
        onClose={onEndFlow}
      />
      <Stack space="8">
        <Stack>
          {canSplitBill && (
            <>
              <Heading level={2}>Split the bill</Heading>
              <Text>
                Let us know how you&apos;d like to split the bill and we&apos;ll
                let you know how much to pay.
              </Text>
            </>
          )}

          {!canSplitBill && (
            <>
              <Heading level={2}>Pay a share of the bill</Heading>

              <Text>
                Your host has let us know how many guests are at the table.
              </Text>
            </>
          )}
        </Stack>

        {hasRemainingSplitChanged && (
          <Alert
            tone="information"
            onClose={() => setRemainingSplitChanged(false)}
          >
            One of your party has just paid part of the bill, please double
            check your share and continue
          </Alert>
        )}

        <Stack space="6">
          <Stack space="2">
            <Stack space="0">
              <Text tone="subtle" align="center">
                {canSplitBill ? "Bill total" : "Left to pay"}
              </Text>
              <Text size="3xl" align="center">
                {total}
              </Text>
            </Stack>

            <VisualSplitIndicator
              numberOfPaidGuests={numberOfPaidGuests}
              splitBy={state.splitBy}
              splitSize={state.splitSize}
            />
          </Stack>

          {canSplitBill && (
            <div className="flex w-full gap-2 text-center font-semibold">
              <div className="flex flex-shrink-0 items-end">
                <ButtonIcon
                  icon="Minus"
                  variant="soft"
                  onClick={() => onSplitByChange("-1")}
                />
              </div>
              <div className="flex-1">
                <TextField
                  className="text-center"
                  label="How many people want to pay?"
                  onChange={(e) => onSplitByChange(e.target.value)}
                  value={state.splitBy}
                  inputProps={{
                    inputClassName: "text-center",
                  }}
                  fieldProps={{
                    labelClassName: "font-semibold",
                  }}
                />
              </div>
              <div className="flex flex-shrink-0 items-end">
                <ButtonIcon
                  icon="Plus"
                  variant="soft"
                  onClick={() => onSplitByChange("+1")}
                />
              </div>
            </div>
          )}

          <div className="flex w-full gap-2 text-center font-semibold">
            <div className="flex flex-shrink-0 items-end">
              <ButtonIcon
                icon="Minus"
                variant="soft"
                onClick={() => onSplitSizeChange("-1")}
              />
            </div>
            <div className="flex-1">
              <TextField
                label="How many people do you want to pay for?"
                onChange={(e) => onSplitSizeChange(e.target.value)}
                value={state.splitSize}
                inputProps={{
                  inputClassName: "text-center",
                }}
                fieldProps={{
                  labelClassName: "font-semibold",
                }}
              />
            </div>
            <div className="flex flex-shrink-0 items-end">
              <ButtonIcon
                icon="Plus"
                variant="soft"
                onClick={() => onSplitSizeChange("+1")}
              />
            </div>
          </div>

          <Text size="xl" align="center">
            You will pay{" "}
            <strong className="font-semibold">{share.preTipTotal}</strong>
          </Text>
        </Stack>

        <Button
          variant="solid"
          onClick={onSubmit}
          isDisabled={!canSubmit}
          isFullWidth
        >
          Continue
        </Button>
      </Stack>
    </>
  )
}
