const ContactUs = () => {
  return (
    <div className="pt-2 w-full px-6 flex flex-1 flex-col justify-end">
      <div className="text-harmony-text-subtle dark:text-harmony-text-subtle-dark border-t border-dashed border-harmony-border-base dark:border-harmony-border-base-dark py-4">
        Powered by Zumi
      </div>
      <div className="text-harmony-text-base text-sm md:text-lg text-center">
        If you require support or would like to reach out to us direct for any
        reason, please click contact us below.
      </div>
      <div className="my-6 inline-flex justify-center">
        <a
          href="mailto:info@zumi.app"
          className="px-12 py-3 bg-harmony-button-solid-neutral-bg rounded-3xl text-center"
        >
          Contact us
        </a>
      </div>
    </div>
  )
}

export default ContactUs
