import { z } from "zod"
import { Service } from "./Service"

const restaurantSchema = z.object({
  email: z.string(),
  emergency_shutoff: z.boolean(),
  name: z.string(),
  payment_gateway: z
    .object({ publishableKey: z.string(), type: z.string() })
    .optional(),
  phone_number: z.string(),
  social: z.object({
    facebook: z.string().optional(),
    instagram: z.string().optional(),
    socialId: z.string().optional(),
    tiktok: z.string().optional(),
    x: z.string().optional(),
  }),
  vat_number: z.string(),
})

export class RestaurantService extends Service {
  async get(tenantId, subtenantId) {
    const endpoint = [`${this.hostname}restaurant/info?tenantId=${tenantId}`]

    if (subtenantId) {
      endpoint.push(`subtenantId=${subtenantId}`)
    }

    const response = await fetch(endpoint.join("&"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    const data = await response.json()

    const isSuccessful = response.status === 200

    if (!isSuccessful) {
      throw new Error(data.error ?? "Unexpected error")
    }

    return restaurantSchema.parse(data)
  }
}
