import PropTypes from "prop-types"

/**
 * A spread is used to distribute items at either end of a full width container.
 *
 * @param {Object} props - The component props
 * @param {"none" | "1" | "2" | "4" | "6" | "8" | "12"} [props.space=4] - The minimum spacing between elements, aligns to tailwind spacing classes
 * @param {React.ReactNode} props.children - The content of the stack.
 */
export function Spread({ space = "4", children }) {
  const gapClasses = {
    0: "gap-0",
    1: "gap-1",
    2: "gap-2",
    4: "gap-4",
    6: "gap-6",
    8: "gap-8",
    12: "gap-12",
  }

  return (
    <div
      className={`w-full flex flex-row justify-between items-center ${gapClasses[space]}`}
    >
      {children}
    </div>
  )
}

Spread.propTypes = {
  space: PropTypes.oneOf(["none", "1", "2", "4", "6", "8", "12"]),
  children: PropTypes.node.isRequired,
}
