/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{html,js,jsx}"],
  darkMode: [
    "variant",
    [
      "@media (prefers-color-scheme: dark) { &:not([data-mode='light'] *) }",
      "[data-mode='dark'] &",
    ],
  ],
  theme: {
    boxShadow: {
      "lg-no-spread":
        "0 8px 16px 0px var(--tw-shadow-color), 0 4px 6px 0px var(--tw-shadow-color)",
    },
    extend: {
      transitionDuration: {
        1000: "1000ms",
      },
      animation: {
        "fade-pulse": "pulse 2s ease-in-out infinite",
      },
      keyframes: {
        pulse: {
          "0%": { opacity: "0" },
          "50%": { opacity: "1" },
          "100%": { opacity: "0" },
        },
      },
    },
  },
  plugins: [],
}
