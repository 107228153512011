import { Button, Heading, Icon } from "@harmony"

export function PaymentSuccessful({ onClose }) {
  return (
    <div className="flex flex-col gap-8 text-center mt-6 mb-4">
      <span className="mt-0.5">
        <Icon
          name="CheckCircle"
          size="48px"
          className="fill-harmony-positive-500 m-auto"
        />
      </span>
      <Heading level={2}>Payment successful</Heading>
      <Button variant="solid" onClick={onClose} isFullWidth>
        Close
      </Button>
    </div>
  )
}
