import { Elements } from "@stripe/react-stripe-js"
import getStripeInstance from "services/stripe"
import { StripeCardPaymentForm } from "./StripeCardPaymentForm"
import { useEffect, useState } from "react"
import {
  useCheckout,
  usePaymentService,
  useReservation,
  useRestaurant,
} from "modules/checkout/hooks"
import { getThemePreset } from "utils/theme"

const { theme } = getThemePreset()

const harmony = theme.extend.colors.harmony

const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches

const stripeAppearance = {
  theme: "none",
  variables: {
    colorText: isDarkMode ? harmony.text.base.dark : harmony.text.base.DEFAULT,
    colorBackground: "transparent",
    borderRadius: "0.5rem",
  },
  rules: {
    ".Input": {
      height: "48px",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      display: "flex",
      alignItems: "center",
      border: isDarkMode
        ? `1px solid ${harmony.border.base.dark}`
        : `2px solid ${harmony.border.base.DEFAULT}`,
      borderRadius: "0.5rem",
      backgroundColor: "transparent",
      transition: "border-color 0.2s ease",
    },
    ".Input:focus": {
      outline: isDarkMode
        ? `1px solid ${harmony.border.base.dark}`
        : `1px solid ${harmony.border.base.DEFAULT}`,
    },
    ".Input--invalid": {
      border: `2px solid ${harmony.critical[700]}`,
    },
    ".Label": {
      color: isDarkMode ? harmony.text.base.dark : harmony.text.base.DEFAULT,
      marginBottom: "8px",
    },
    ".AccordionItem": {
      border: 0,
      boxShadow: "none",
    },
  },
}

function usePaymentIntent({ reservationId, state, share, user }) {
  const paymentService = usePaymentService()
  const [paymentIntent, setPaymentIntent] = useState(null)

  useEffect(() => {
    paymentService
      .createPaymentIntent(reservationId, state, share, user)
      .then((data) => setPaymentIntent(data.paymentIntent))
      .catch((e) => {
        setPaymentIntent(null)
        console.error("Failed to create payment intent", e)
      })
  }, [reservationId, state, share])

  return paymentIntent
}

export function StripeCardPayment({ onPaymentConfirmation, onPaymentError }) {
  const [isLoading, setLoading] = useState(true)

  const { state } = useCheckout()

  const { restaurant } = useRestaurant()
  const { reservation } = useReservation()

  const paymentIntent = usePaymentIntent({
    reservationId: reservation.reservationId,
    state,
    share: state.share,
    user: {
      email: state.user.emailAddress,
      name: state.user.fullName,
    },
  })

  const stripePromise = getStripeInstance(
    restaurant.payment_gateway.publishableKey,
  )

  useEffect(() => {
    if (stripePromise) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [stripePromise])

  if (isLoading || !paymentIntent || !paymentIntent.client_secret) {
    return null
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: paymentIntent.client_secret,
        appearance: stripeAppearance,
      }}
    >
      <StripeCardPaymentForm
        paymentIntent={paymentIntent}
        onPaymentError={onPaymentError}
        onPaymentConfirmation={onPaymentConfirmation}
      />
    </Elements>
  )
}
