import {
  Button,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  TextField,
} from "@harmony"

import { Routes } from "../.."
import { TipType } from "../../api/PaymentService"
import {
  EVENT_TIP_SELECTION,
  useEventTracker,
  useNavigateExtended,
} from "../../hooks"
import { useCheckout } from "../../hooks/useCheckout"
import { ModalControls } from "../../parts"

function isChecked(currentValue, value, customAmount) {
  return !customAmount && parseFloat(currentValue) === value
}

function isValidPercent(value) {
  return /^(0(\.\d{1,3})?|1(\.0{1,3})?|1)$/.test(value) || value === ""
}

function isValidCustom(value) {
  return /^\d+(\.\d{0,2})?$/.test(value) || value === ""
}

export function AddTip() {
  const { setTip, state, onEndFlow } = useCheckout()
  const navigate = useNavigateExtended()
  const { onEvent } = useEventTracker()

  const customAmount = state.tipType === TipType.Fixed ? state.tipAmount : ""
  const percentAmount = state.tipType === TipType.Percent ? state.tipAmount : ""

  function onConfirm() {
    navigate.append(Routes.PayBill)
  }

  function onRadioChange(e) {
    const value = e.target.value

    if (isValidPercent(value)) {
      onEvent(EVENT_TIP_SELECTION, {
        tipAmount: parseFloat(value),
        tipType: TipType.Percent,
      })

      setTip({
        tipAmount: parseFloat(value),
        tipType: TipType.Percent,
      })
    }
  }

  function onCustomChange(e) {
    const value = e.target.value

    if (isValidCustom(value)) {
      onEvent(EVENT_TIP_SELECTION, {
        tipAmount: value ? value : 0,
        tipType: TipType.Fixed,
      })

      setTip({
        tipAmount: value ? value : 0,
        tipType: TipType.Fixed,
      })
    }
  }

  const canSubmit = customAmount !== "" || percentAmount !== ""

  return (
    <>
      <ModalControls
        onNavigateBack={() => {
          setTip({
            tipType: "fixed",
            tipAmount: "",
          })

          navigate.previous()
        }}
        onClose={onEndFlow}
      />
      <Stack>
        <Heading level={2}>Leave a tip?</Heading>

        <Text>
          All tips go directly to your server{" "}
          {state.server && <strong>{state.server}</strong>} and the wider
          service team.
        </Text>

        <Stack gap="6">
          <RadioGroup
            label="Tip amount"
            fieldProps={{
              labelClassName: "font-semibold",
            }}
          >
            <Radio
              label="10%"
              value="0.10"
              name="tip"
              onChange={onRadioChange}
              checked={isChecked(percentAmount, 0.1, customAmount)}
            />
            <Radio
              label="7.5%"
              value="0.075"
              name="tip"
              onChange={onRadioChange}
              checked={isChecked(percentAmount, 0.075, customAmount)}
            />
            <Radio
              label="5%"
              value="0.05"
              name="tip"
              onChange={onRadioChange}
              checked={isChecked(percentAmount, 0.05, customAmount)}
            />
            <Radio
              label="No, don't add a tip"
              value="0"
              name="tip"
              onChange={onRadioChange}
              checked={isChecked(percentAmount, 0, customAmount)}
            />
          </RadioGroup>

          <TextField
            label="Custom amount"
            startAdornment="£" // Todo
            value={customAmount}
            onChange={onCustomChange}
            placeholder="Enter a custom tip"
          />
        </Stack>

        <Button
          variant="solid"
          isFullWidth
          onClick={onConfirm}
          disabled={!canSubmit}
        >
          Continue
        </Button>
      </Stack>
    </>
  )
}
