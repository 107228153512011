import PropTypes from "prop-types"

/**
 * A bleed is used to create a negative margin around an element allowing it to overflow its container.
 *
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The content of the bleed.
 * @param {"1" | "2" | "4" | "6" | "8" | "12"} [props.bleedX] - The amount of bleed on the x-axis.
 * @param {"1" | "2" | "4" | "6" | "8" | "12"} [props.bleedY] - The amount of bleed on the y-axis.
 * @param {"1" | "2" | "4" | "6" | "8" | "12"} [props.bleedRight] - The amount of bleed on the right-axis.
 * @param {"1" | "2" | "4" | "6" | "8" | "12"} [props.bleedLeft] - The amount of bleed on the left-axis.
 * @param {"1" | "2" | "4" | "6" | "8" | "12"} [props.bleedTop] - The amount of bleed on the top-axis.
 * @param {"1" | "2" | "4" | "6" | "8" | "12"} [props.bleedBottom] - The amount of bleed on the bottom-axis.
 */

export function Bleed({
  children,
  bleedX,
  bleedY,
  bleedRight,
  bleedLeft,
  bleedTop,
  bleedBottom,
}) {
  const classes = []

  const marginClasses = {
    x: {
      1: "-mx-1",
      2: "-mx-2",
      4: "-mx-4",
      6: "-mx-6",
      8: "-mx-8",
      12: "-mx-12",
    },
    y: {
      1: "-my-1",
      2: "-my-2",
      4: "-my-4",
      6: "-my-6",
      8: "-my-8",
      12: "-my-12",
    },
    right: {
      1: "-mr-1",
      2: "-mr-2",
      4: "-mr-4",
      6: "-mr-6",
      8: "-mr-8",
      12: "-mr-12",
    },
    left: {
      1: "-ml-1",
      2: "-ml-2",
      4: "-ml-4",
      6: "-ml-6",
      8: "-ml-8",
      12: "-ml-12",
    },
    top: {
      1: "-mt-1",
      2: "-mt-2",
      4: "-mt-4",
      6: "-mt-6",
      8: "-mt-8",
      12: "-mt-12",
    },
    bottom: {
      1: "-mb-1",
      2: "-mb-2",
      4: "-mb-4",
      6: "-mb-6",
      8: "-mb-8",
      12: "-mb-12",
    },
  }

  if (bleedX) classes.push(marginClasses.x[bleedX])
  if (bleedY) classes.push(marginClasses.y[bleedY])
  if (bleedRight) classes.push(marginClasses.right[bleedRight])
  if (bleedLeft) classes.push(marginClasses.left[bleedLeft])
  if (bleedTop) classes.push(marginClasses.top[bleedTop])
  if (bleedBottom) classes.push(marginClasses.bottom[bleedBottom])

  return (
    <div className={`overflow-visible ${classes.join(" ")}`}>
      <div className="relative">{children}</div>
    </div>
  )
}

Bleed.propTypes = {
  children: PropTypes.node.isRequired,
  bleedX: PropTypes.oneOf(["1", "2", "4", "6", "8", "12"]),
  bleedY: PropTypes.oneOf(["1", "2", "4", "6", "8", "12"]),
  bleedRight: PropTypes.oneOf(["1", "2", "4", "6", "8", "12"]),
  bleedLeft: PropTypes.oneOf(["1", "2", "4", "6", "8", "12"]),
  bleedTop: PropTypes.oneOf(["1", "2", "4", "6", "8", "12"]),
  bleedBottom: PropTypes.oneOf(["1", "2", "4", "6", "8", "12"]),
}
