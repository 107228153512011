import { useTenantConfig } from "@hooks"
import { useEffect, useRef, useState } from "react"
import { LoadingContext } from "./LoadingContext"

const LOADING_DELAY_MS = 1000

/**
 * The Loading component is intended only for initial load.
 * Once hidden, it will never be shown again — even on future data refetches.
 * This prevents providers that consume this component from re-triggering the loading state after the app has mounted.
 */

export function Loading({ isLoading }) {
  const { logo, name } = useTenantConfig()
  const tenantLogo = logo || "images/zumiLogo.svg"
  const [isVisible, setIsVisible] = useState(true)
  const [shouldStartFadeOut, setShouldStartFadeOut] = useState(false)

  useEffect(() => {
    if (!isLoading && !shouldStartFadeOut) {
      setShouldStartFadeOut(true)
    }
  }, [isLoading, shouldStartFadeOut])

  useEffect(() => {
    if (shouldStartFadeOut) {
      const fadeOutTimer = setTimeout(() => {
        setIsVisible(false)
      }, 1000)

      return () => clearTimeout(fadeOutTimer)
    }
  }, [shouldStartFadeOut])

  if (!isVisible) {
    return null
  }

  return (
    <div
      className={`duration-800 fixed inset-0 z-50 flex items-center justify-center bg-harmony-surface-base transition-opacity ease-in-out dark:bg-harmony-surface-base-dark ${
        shouldStartFadeOut ? "opacity-0" : "opacity-100"
      }`}
    >
      <img
        src={tenantLogo}
        alt={`${name || ""} logo`}
        className={`w-[180px] sm:w-[220px] md:w-[240px] lg:w-[260px] xl:w-[280px] 2xl:w-[320px] max-w-[320px] object-contain animate-fade-pulse motion-reduce:animate-none motion-reduce:opacity-100`}
      />
    </div>
  )
}

export function LoadingProvider({ children }) {
  const [isLoading, setLoadingState] = useState(true)
  const timeoutRef = useRef()

  const setLoading = (bool) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setLoadingState(bool)
    }, LOADING_DELAY_MS)
  }

  return (
    <LoadingContext.Provider value={{ setLoading }}>
      <Loading isLoading={isLoading} />
      {children}
    </LoadingContext.Provider>
  )
}
