export function Item({
  item,
  isSelected = false,
  isHighlighted = false,
  onSelection = () => {},
  id,
}) {
  const handleEvents = (e) => {
    if (e.type === "mousedown") {
      e.preventDefault() // Prevent the main dropdown losing focus
      onSelection(item.value)
      return
    }

    if (e.type === "keydown") {
      if (e.key === "Enter" || e.key === " ") {
        e.preventDefault() // Prevent default behavior for space to avoid page scrolling
        onSelection(item.value)
      }
    }
  }

  const selectItemClasses = ["p-2", "px-4", "cursor-pointer"]

  if (isSelected) {
    selectItemClasses.push("bg-neutral-300", "font-semibold")
  }

  if (isHighlighted) {
    selectItemClasses.push("bg-neutral-200")
  }

  if (isHighlighted && isSelected) {
    selectItemClasses.push("bg-neutral-400")
  }

  return (
    <li
      id={id}
      role="option"
      onMouseDown={handleEvents}
      onKeyDown={handleEvents}
      tabIndex={-1}
      className={selectItemClasses.join(" ")}
      aria-selected={isSelected}
    >
      {item.label}
    </li>
  )
}
