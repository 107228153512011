import { useState } from "react"

export function useKeyboardNavigation({ items, isOpen, setOpen, onSelection }) {
  const [highlightedIndex, setHighlightedIndex] = useState(-1)

  const [_searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [_searchCycleIndex, setSearchCycleIndex] = useState(0)

  const resetSearch = () => {
    setSearchTerm("")
    setSearchResults([])
    setSearchCycleIndex(0)
  }

  const onKeyDown = (e) => {
    const { key } = e

    const isTabbing = key === "Tab"

    if (isTabbing) {
      return
    }

    if (!isOpen) {
      const shouldOpen = ["Enter", " "].includes(key)

      if (shouldOpen) {
        e.preventDefault()
        return setOpen(true)
      }
    } else {
      const shouldClose = key === "Escape"
      if (shouldClose) {
        e.preventDefault()
        return setOpen(false)
      }

      const shouldSelectItem = ["Enter", " "].includes(key)
      if (shouldSelectItem) {
        e.preventDefault()
        return onSelection(items[highlightedIndex]?.value)
      }
    }

    const isNavigatingUp = key === "ArrowUp"
    if (isNavigatingUp) {
      e.preventDefault()

      return setHighlightedIndex((index) => {
        const prevIndex = index <= 0 ? items.length - 1 : index - 1

        if (!isOpen) {
          onSelection(items[prevIndex]?.value)
        }

        return prevIndex
      })
    }

    const isNavigatingDown = key === "ArrowDown"
    if (isNavigatingDown) {
      e.preventDefault()

      return setHighlightedIndex((index) => {
        const nextIndex = index === items.length - 1 ? 0 : index + 1

        if (!isOpen) {
          onSelection(items[nextIndex]?.value)
        }

        return nextIndex
      })
    }

    if (/^[a-zA-Z0-9]$/.test(key)) {
      e.preventDefault()

      setSearchTerm((searchTerm) => {
        const newSearchTerm = searchTerm + key.toLowerCase()
        const matchedIndexes = items
          .map((item, index) => ({
            index,
            match: item.label.toLowerCase().startsWith(newSearchTerm),
          }))
          .filter((item) => item.match)
          .map((item) => item.index)

        if (matchedIndexes.length > 0) {
          setSearchResults(matchedIndexes)
          setSearchCycleIndex(0)

          if (isOpen) {
            setHighlightedIndex(matchedIndexes[0])
          } else {
            onSelection(items[matchedIndexes[0]].value)
          }
        }

        return newSearchTerm
      })

      if (searchTimeout) clearTimeout(searchTimeout)
      setSearchTimeout(setTimeout(resetSearch, 1000))
    }

    if (searchResults.length > 0 && searchResults.includes(highlightedIndex)) {
      setSearchCycleIndex((searchCycleIndex) => {
        const nextIndex = (searchCycleIndex + 1) % searchResults.length

        if (isOpen) {
          setHighlightedIndex(searchResults[nextIndex])
        } else {
          onSelection(items[searchResults[nextIndex]].value)
        }

        return nextIndex
      })
    }
  }

  return { setHighlightedIndex, highlightedIndex, onKeyDown }
}
