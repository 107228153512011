import { ModalControls } from "modules/checkout/parts"
import { useNavigateExtended, useCheckout } from "modules/checkout/hooks"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Button, TextField, Dropdown, Heading, Text, Stack } from "@harmony"
import countryCodes from "utils/country_codes_ISO-3366-1Alpha-3.json"
import { Routes } from "modules/checkout/constants"
const getCountryCodesList = () => {
  return countryCodes.map((country) => ({
    value: country.code,
    label: country.name,
  }))
}
const schema = z.object({
  email: z.string().email("Invalid email").min(1, "Email is required"),
  billingAddress: z.string().min(1, "Billing address is required"),
  city: z.string().min(1, "City is required"),
  countryCode: z.string().min(1, "Country code is required"),
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  postalCode: z.string().min(1, "Postal code is required"),
  phone: z.string().min(1, "Phone number is required"),
})

export const PaymentCardUserDetailsBill = () => {
  const navigate = useNavigateExtended()
  const { onEndFlow, state, setUserDataCardPayment } = useCheckout()

  const {
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: state.userDataCardPayment.email,
      billingAddress: state.userDataCardPayment.billingAddress,
      city: state.userDataCardPayment.city,
      countryCode: state.userDataCardPayment.countryCode,
      firstName: state.userDataCardPayment.firstName,
      lastName: state.userDataCardPayment.lastName,
      postalCode: state.userDataCardPayment.postalCode,
      phone: state.userDataCardPayment.phone,
    },
  })

  const onSubmit = async (data) => {
    const isValid = await trigger()
    if (isValid) {
      setUserDataCardPayment(data)
      navigate.append(Routes.PaymentCardDetailsBill)
    }
  }

  const handleFieldChange = (name) => (e) => {
    const value = e?.target?.value ?? e
    setValue(name, value)
    trigger(name)
  }

  return (
    <>
      <ModalControls
        onNavigateBack={() => {
          navigate.previous()
        }}
        onClose={onEndFlow}
      />
      <Stack>
        <Heading level={2}>Enter personal details</Heading>
        <Text>
          For security we need to ask for your details to make a card payment.
        </Text>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <TextField
              label="First Name"
              value={getValues("firstName")}
              onChange={handleFieldChange("firstName")}
              helperText={errors.firstName?.message}
              helperType={errors.firstName ? "critical" : undefined}
            />
            <TextField
              label="Last Name"
              value={getValues("lastName")}
              onChange={handleFieldChange("lastName")}
              helperText={errors.lastName?.message}
              helperType={errors.lastName ? "critical" : undefined}
            />
          </div>
          <TextField
            label="Billing Address"
            value={getValues("billingAddress")}
            onChange={handleFieldChange("billingAddress")}
            helperText={errors.billingAddress?.message}
            helperType={errors.billingAddress ? "critical" : undefined}
          />
          <Dropdown
            label="Country"
            placeholder="Choose a country..."
            items={getCountryCodesList()}
            onChange={(value) => {
              const country = countryCodes.find((c) => c.code === value)
              if (country) {
                setValue("countryCode", country.code)
                setValue("phone", country.dial_code)
                trigger(["countryCode", "phone"])
              }
            }}
            value={getValues("countryCode")}
            defaultValue={getValues("countryCode")}
          />
          <TextField
            label="Postal Code"
            value={getValues("postalCode")}
            onChange={handleFieldChange("postalCode")}
            helperText={errors.postalCode?.message}
            helperType={errors.postalCode ? "critical" : undefined}
          />
          <TextField
            label="City"
            value={getValues("city")}
            onChange={handleFieldChange("city")}
            helperText={errors.city?.message}
            helperType={errors.city ? "critical" : undefined}
          />
          <TextField
            label="Email"
            value={getValues("email")}
            onChange={handleFieldChange("email")}
            helperText={errors.email?.message}
            helperType={errors.email ? "critical" : undefined}
          />
          <TextField
            label="Phone"
            value={getValues("phone")}
            onChange={handleFieldChange("phone")}
            helperText={errors.phone?.message}
            helperType={errors.phone ? "critical" : undefined}
          />

          <Button type="submit" className="w-full mt-6" variant="solid">
            Continue to Payment
          </Button>
        </form>
      </Stack>
    </>
  )
}
