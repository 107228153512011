import { forwardRef, useId } from "react"
import { Field } from "../../Field"

const RadioGroup = forwardRef(
  (
    {
      id,
      label,
      children,
      fieldProps = {
        labelClassName: "",
      },
    },
    _ref,
  ) => {
    const fieldId = id || useId()

    return (
      <Field
        label={label}
        fieldId={fieldId}
        fieldProps={fieldProps}
        component="fieldset"
      >
        <div className="flex flex-col gap-4">{children}</div>
      </Field>
    )
  },
)

RadioGroup.displayName = "RadioGroup"

export { RadioGroup }
