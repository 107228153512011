import { loadStripe } from "@stripe/stripe-js/pure"

var stripeInstance = null

const getStripeInstance = (publishedKey, advancedFraudSignals = false) => {
  if (publishedKey == "" || publishedKey == undefined) {
    throw new Error("Invalid stripe key")
  }
  if (!stripeInstance) {
    loadStripe.setLoadParameters({ advancedFraudSignals })
    stripeInstance = loadStripe(publishedKey)
  }

  return stripeInstance
}

export default getStripeInstance
