import { Error } from "./Error"

export function RestaurantShutdown() {
  return (
    <Error heading="Service offline">
      <p>It seems the restaurant bill service is currently offline.</p>
      <p>
        Please check with the restaurant to make sure you are using the correct
        bill link, and try again.
      </p>
      <p>
        If this problem persists, please speak to your wait staff who can check
        the details for you.
      </p>
    </Error>
  )
}
