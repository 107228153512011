export const PAYMENT_PROVIDERS = {
  FREEDOMPAY: "freedompay",
  STRIPE: "stripe",
}
export const CHECKOUT_ROUTE = "/bill-info"

export const Routes = {
  AddTip: "ADD_TIP",
  PassBill: "PASS_BILL",
  PayBill: "PAY_BILL",
  SettleBill: "SETTLE_BILL",
  ShareBill: "SHARE_BILL",
  SplitBill: "SPLIT_BILL",
  PaymentCardUserDetailsBill: "CARD_PAYMENT_USER_DETAILS",
  PaymentCardDetailsBill: "CARD_PAYMENT_DETAILS",
}

export const Paths = {
  [Routes.AddTip]: "add-tip",
  [Routes.PassBill]: "pass-bill",
  [Routes.PayBill]: "pay-bill",
  [Routes.SettleBill]: "settle-bill",
  [Routes.ShareBill]: "share-bill",
  [Routes.SplitBill]: "split-bill",
  [Routes.PaymentCardUserDetailsBill]: "card-payment-user-details",
  [Routes.PaymentCardDetailsBill]: "card-payment-details",
}
