import { Button, Heading, Icon, Stack, Text } from "@harmony"

export function PaymentCancelled({ onClose }) {
  return (
    <div className="flex flex-col gap-8 text-center mt-6 mb-4">
      <span className="mt-0.5">
        <Icon
          name="XCircle"
          size="48px"
          className="fill-harmony-critical-500 m-auto"
        />
      </span>
      <Stack space="1">
        <Heading level={2}>Payment cancelled</Heading>
        <Text align="center">The bill has already been settled.</Text>
      </Stack>
      <Button variant="solid" onClick={onClose} isFullWidth>
        Close
      </Button>
    </div>
  )
}
