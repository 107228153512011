import { useTenantConfig } from "@hooks"
import PropTypes from "prop-types"

export function Logo({ variant = "logo" }) {
  const darkMode = window.matchMedia("(prefers-color-scheme:dark)").matches
  const clientPreferenceVariant = darkMode ? `${variant}_dark` : variant

  const { [clientPreferenceVariant]: logoUrl, icon, name } = useTenantConfig()

  const iconModeClasses = ["s-10"]
  const logoModeClasses = ["h-8 w-auto"]

  const logoClasses = {
    icon: iconModeClasses,
    logo: logoModeClasses,
  }

  if (!logoUrl || !icon || !name) {
    // eslint-disable-next-line no-console
    console.error("<Logo/> - Tenant config is missing logo, icon or name")
    return null
  }

  return (
    <img
      src={logoUrl}
      alt={`${name} ${variant}`}
      className={logoClasses[variant]}
    />
  )
}

Logo.propTypes = {
  variant: PropTypes.oneOf(["logo", "icon"]),
}
