import { Service } from "./Service"
import { z } from "zod"

export const TipType = {
  Fixed: "fixed",
  Percent: "percentage",
}

const ConfirmPaymentIntentSchema = z.object({
  paymentRecord: z.object({
    invoiceId: z.string(),
    paymentId: z.string(),
    paid: z.string(),
    allocation: z.object({
      tip: z.string(),
      service: z.string(),
      tax: z.string(),
      subtotal: z.string(),
      splitSize: z.number(),
    }),
    createdAt: z.number(),
    updatedAt: z.number(),
    id: z.string(),
  }),
  billSettled: z.boolean(),
  success: z.boolean(),
})

const PaymentSchema = z.object({
  isOwner: z.boolean(),
  guestsCount: z.number(),
  splitBy: z.number(),
  remainingSplits: z.number(),
  request: z.object({
    splitBy: z.number(),
    splitSize: z.number(),
    tipType: z.string(),
    tipAmount: z.string(),
  }),
  server: z.string(),
  currency: z.string(),
  share: z.object({
    subtotal: z.string(),
    tax: z.string(),
    service: z.string(),
    total: z.string(),
    tip: z.string(),
    preTipTotal: z.string(),
  }),
  shareRaw: z.object({
    subtotal: z.number(),
    tax: z.number(),
    service: z.number(),
    total: z.number(),
    tip: z.number(),
    preTipTotal: z.number(),
  }),
  total: z.object({
    subtotal: z.string(),
    tax: z.string(),
    service: z.string(),
    total: z.string(),
  }),
  collected: z.object({
    subtotal: z.string(),
    tax: z.string(),
    service: z.string(),
    total: z.string(),
    tip: z.string(),
  }),
  paid: z.boolean(),
  timestamp: z.number(),
})

export class PaymentService extends Service {
  constructor() {
    super()
    this.abortController = null
  }

  async getPaymentProviderConfig(reservationId) {
    const response = await fetch(
      `${this.hostname}payment/config/${reservationId}`,
    )
    const data = await response.json()
    const isSuccessful = response.status === 200 || response.status === 201

    if (!isSuccessful) {
      throw new Error(data.error ?? "Unexpected error")
    }

    return data
  }

  async createPaymentIntent(
    reservationId,
    {
      splitBy = 1,
      splitSize = 1,
      tipType = TipType.Fixed,
      tipAmount = 0,
      forceSplitBy,
      userDataCardPayment = null,
    },
    paymentRequest,
    userInfo = null,
    schema = "light",
    iframeTypes = [],
  ) {
    const response = await fetch(
      `${this.hostname}payment/intent/${reservationId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          splitBy,
          splitSize,
          tipType,
          tipAmount,
          paymentRequest,
          userInfo,
          forceSplitBy,
          schema,
          formData3ds: userDataCardPayment,
          iframeTypes,
        }),
      },
    )

    const data = await response.json()

    const isSuccessful = response.status === 200 || response.status === 201

    if (!isSuccessful) {
      throw new Error(data.error ?? "Unexpected error")
    }

    return data
  }

  async confirmPaymentIntent({
    reservationId,
    paymentId,
    sessionKey,
    state,
    formData,
    paymentType,
  }) {
    const response = await fetch(
      `${this.hostname}payment/confirm/${reservationId}/${paymentId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionKey,
          state,
          formData,
          paymentType,
        }),
      },
    )
    const data = await response.json()

    const isSuccessful = response.status === 200 || response.status === 201

    if (!isSuccessful) {
      throw new Error(data.error ?? "Unexpected error")
    }

    return ConfirmPaymentIntentSchema.parse(data)
  }

  async get(
    reservationId,
    {
      splitBy = 1,
      splitSize = 1,
      tipType = TipType.Fixed,
      tipAmount = 0,
      forceSplitBy = false,
    },
  ) {
    if (this.abortController) {
      this.abortController.abort()
    }

    this.abortController = new AbortController()
    const { signal } = this.abortController

    try {
      const response = await fetch(
        `${this.hostname}payment/bill/${reservationId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            splitBy,
            splitSize,
            tipType,
            tipAmount,
            forceSplitBy,
          }),
          signal,
        },
      )

      if (!response.ok) {
        if (signal.aborted) {
          console.warn("Fetch request aborted")
          return null
        }
        throw new Error("Network response was not ok")
      }

      const data = await response.json()

      return PaymentSchema.parse(data)
    } catch (error) {
      if (error.name === "AbortError") {
        console.warn("PaymentService request aborted")
        return null
      }
      throw error
    }
  }

  // Todo - ZM-414
  async getWithoutAbort(
    reservationId,
    {
      splitBy = 1,
      splitSize = 1,
      tipType = TipType.Fixed,
      tipAmount = 0,
      forceSplitBy = false,
    },
  ) {
    try {
      const response = await fetch(
        `${this.hostname}payment/bill/${reservationId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            splitBy,
            splitSize,
            tipType,
            tipAmount,
            forceSplitBy,
          }),
        },
      )

      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const data = await response.json()

      return PaymentSchema.parse(data)
    } catch (error) {
      throw error
    }
  }
}
