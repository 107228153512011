import { TextLink } from "@harmony"
import CustomDrawer from "components/custom/CustomDrawer"
import { CheckWhiteIcon, CustomLoader } from "components/custom/Icons"
import { useState } from "react"
import { toast } from "react-toastify"

export function ReportProblem({ onSubmit }) {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      await onSubmit(message)

      toast.success("Problem has been reported successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })

      setSubmitted(true)
      setOpen(false)
      setMessage("")
    } catch (err) {
      toast.error(err.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setSubmitted(false)
    setMessage("")
  }

  return (
    <div>
      <div className="flex items-center justify-center">
        <TextLink
          className="font-semibold no-underline"
          onClick={() => setOpen(true)}
        >
          Problem with your bill?
        </TextLink>
      </div>
      <CustomDrawer open={open} onClose={handleClose}>
        {submitted ? (
          <div className="w-full gap-5">
            <p className="w-full text-center text-harmony-text-base dark:text-harmony-text-base-dark">
              <CheckWhiteIcon className="text-6xl" />
            </p>
            <p className="my-4 text-xl text-center text-harmony-text-base dark:text-harmony-text-base-dark">
              Problem submitted
            </p>
            <p className="mb-5 text-center text-harmony-button-solid-neutral-fg">
              Thank you for submitting your comments. We&apos;ll get back to you
              shortly.
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="w-full">
            <p className="mb-4 text-2xl font-semibold text-center text-harmony-text-base">
              Problem with your bill?
            </p>
            <textarea
              required
              placeholder="We're sorry! Please tell us what happened..."
              rows={4}
              className="w-full p-4 bg-transparent border border-harmony-button-outline-neutral-border text-harmony-text-base rounded-2xl"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
            <button
              className="flex items-center justify-center w-full gap-2 p-2 mt-5 text-lg font-semibold rounded-full cursor-pointer bg-harmony-button-solid-neutral-bg text-harmony-button-solid-neutral-fg md:p-3"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center gap-3 text-white">
                  <CustomLoader height={24} width={24} />
                  Loading...
                </div>
              ) : (
                "Submit Problem"
              )}
            </button>
          </form>
        )}
        <div className="flex items-center justify-center w-full mt-4">
          <p
            onClick={handleClose}
            className="text-center underline cursor-pointer text-harmony-text-base"
          >
            Cancel
          </p>
        </div>
      </CustomDrawer>
    </div>
  )
}
