import { useEffect } from "react"

// remove utm_ parameters from the query string if present, without reloading the page
export const useCleanParams = () => {
  useEffect(() => {
    if (window.URLSearchParams && window.history) {
      const newSearchParams = new URLSearchParams(window.location.search)
      const urlShouldBeCleaned = [...newSearchParams.keys()].reduce(
        (acc, key) => {
          if (key.startsWith("utm_")) {
            newSearchParams.delete(key)
            return true
          }

          return acc
        },
        false,
      )

      if (urlShouldBeCleaned) {
        window.history.replaceState(
          null,
          "",
          `${window.location.pathname}?${newSearchParams.toString()}${window.location.hash}`,
        )
      }
    }
  }, [])
}
