export function ReservationDetails({
  server,
  dateTime,
  table,
  reservationCode,
  receiptNum,
}) {
  const billDetails = [
    { label: "Server", value: server },
    {
      label: "Date / Time",
      value: `${new Date(dateTime).toLocaleDateString()} / ${new Date(dateTime).toLocaleTimeString()}`,
    },
    { label: "Table Number", value: table },
    { label: "Reservation Number", value: reservationCode },
    { label: "Bill Number", value: receiptNum },
  ]

  return (
    <div className="last:pb-2">
      {billDetails.map((detail, index) => (
        <dl key={index} className="flex items-center justify-between text-sm">
          <dt>{detail.label}</dt>
          <dd className="font-semibold">{detail.value}</dd>
        </dl>
      ))}
    </div>
  )
}
