import { z } from "zod"
import { Service } from "./Service"

const ShareInformationSchema = z.string().url()

export class ReservationService extends Service {
  async getReservationSharingInformation(reservationId) {
    const response = await fetch(
      `${this.hostname}reservationV2/share/${reservationId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    )

    const data = await response.json()

    const isSuccessful = response.status === 200

    if (!isSuccessful) {
      throw new Error(data.error ?? "Unexpected error")
    }

    return ShareInformationSchema.parse(data)
  }
}
