import { useEffect, useState } from "react"

const useSchema = () => {
  const [schema, setSchema] = useState(() => {
    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light"
  })

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)")

    const handleChange = (e) => {
      const newSchema = e.matches ? "dark" : "light"
      setSchema(newSchema)
    }

    // Add listener for schema changes
    mediaQuery.addEventListener("change", handleChange)

    // Cleanup listener
    return () => mediaQuery.removeEventListener("change", handleChange)
  }, [schema])

  return { schema }
}

export default useSchema
