import { useState } from "react"
import { Button } from "@harmony"
import { StripeWalletPayment } from "./StripeWalletPayment"
import { StripeCardPayment } from "./StripeCardPayment"
import { toast } from "react-toastify"
import {
  useCheckout,
  useNavigateExtended,
  usePaymentService,
  useReservation,
} from "modules/checkout/hooks"
import { PAYMENT_STATE } from "modules/checkout/reducers/helpers"
import { Routes } from "modules/checkout/constants"

export function Stripe() {
  const { setPaySplitSize, onPayment, getPayment, setPaymentState, state } =
    useCheckout()
  const paymentService = usePaymentService()
  const { reservation } = useReservation()
  const navigate = useNavigateExtended()

  const [isPayingByCard, setPayingByCard] = useState(false)
  async function onPayCard() {
    setPayingByCard(true)
  }

  async function onPaymentConfirmation({ paymentId }) {
    setPaymentState(PAYMENT_STATE.Processing)

    try {
      const { paid, remainingSplits, shareRaw, request } =
        await paymentService.getWithoutAbort(reservation.reservationId, state)

      // If the bill has been settled, reject
      if (paid) {
        await getPayment()
        setPaymentState(PAYMENT_STATE.Cancelled)
        return
      }

      // If the remainingSplits is less than our splitSize, reject
      if (
        remainingSplits < state.splitSize ||
        remainingSplits < request.splitSize
      ) {
        setPaySplitSize({ splitSize: 1 })
        navigate.append(Routes.SplitBill, { hasRemainingSplitChanged: true })
        return
      }

      // If the splitSize between us and the server is different, reject
      if (state.splitSize !== request.splitSize) {
        setPaySplitSize({ splitSize: 1 })
        navigate.append(Routes.SplitBill, { hasRemainingSplitChanged: true })
        return
      }

      // If we detect issues between our shareRaw and the servers, reject
      if (state.shareRaw.total !== shareRaw.total) {
        setPaySplitSize({ splitSize: 1 })
        navigate.append(Routes.SplitBill, { hasRemainingSplitChanged: true })
        return
      }

      const { paymentRecord } = await paymentService.confirmPaymentIntent({
        reservationId: reservation.reservationId,
        paymentId,
      })

      onPayment({
        ...paymentRecord,
        tip: {
          tipType: state.tipType,
          tipAmount: state.tipAmount,
        },
      })

      await getPayment()

      setPaymentState(PAYMENT_STATE.Successful)
    } catch (e) {
      setPaymentState(PAYMENT_STATE.Failed)
    }
  }

  async function onPaymentError(error) {
    console.error(error)
    toast.error("Failed to process your payment")
  }

  return (
    <>
      <StripeWalletPayment
        onPaymentConfirmation={onPaymentConfirmation}
        onPaymentError={onPaymentError}
      />

      {isPayingByCard ? (
        <StripeCardPayment
          onPaymentConfirmation={(e) => {
            // Prevent refetching a paymentIntent after a successful payment
            setPayingByCard(false)
            return onPaymentConfirmation(e)
          }}
          onPaymentError={onPaymentError}
        />
      ) : (
        <Button
          variant="outline"
          isFullWidth
          onClick={onPayCard}
          startIcon="CreditCard"
        >
          Pay by card
        </Button>
      )}
    </>
  )
}
