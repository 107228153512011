import PropTypes from "prop-types"

/**
 * Text component for displaying text with different tones and weights.
 *
 * @param {Object} props - The props for the Text component.
 * @param {React.ReactNode} props.children - The content of the text.
 * @param {"neutral" | "subtle" | "positive" | "critical" | "caution" | "info"} props.tone - The tone of the text.
 * @param {"regular" | "medium" | "strong"} props.weight - The weight of the text.
 * @param {"left" | "center" | "right"} props.align - The alignment of the text.
 * @param {"span" | "p"} props.component - The component to render the text as.
 */

export function Text({
  children,
  tone = "neutral",
  weight = "regular",
  align = "left",
  size = "base",
  component = "span",
}) {
  const textToneClasses = {
    neutral: ["text-harmony-text-base", "dark:text-harmony-text-base-dark"],
    subtle: ["text-harmony-text-subtle", "dark:text-harmony-text-subtle-dark"],
    positive: ["text-harmony-positive-700", "dark:text-harmony-positive-100"],
    critical: ["text-harmony-critical-700", "dark:text-harmony-critical-100"],
    caution: ["text-harmony-caution-700", "dark:text-harmony-caution-100"],
    info: ["text-harmony-info-700", "dark:text-harmony-info-100"],
  }

  const textWeightClasses = {
    regular: ["font-normal"],
    medium: ["font-semibold"],
    strong: ["font-semibold"],
  }

  const textAlignmentClasses = {
    left: ["text-left"],
    center: ["text-center"],
    right: ["text-right"],
  }

  const textSizeClasses = {
    base: ["text-base"],
    sm: ["text-sm"],
    lg: ["text-lg"],
    xl: ["text-xl"],
    "2xl": ["text-2xl"],
    "3xl": ["text-3xl"],
    "4xl": ["text-4xl"],
    "5xl": ["text-5xl"],
    "6xl": ["text-6xl"],
  }

  const combinedClasses = `${textToneClasses[tone].join(" ")} ${textWeightClasses[weight].join(" ")} ${textAlignmentClasses[align].join(" ")} ${textSizeClasses[size].join(" ")}`

  if (component === "span") {
    return <span className={combinedClasses}>{children}</span>
  }

  if (component === "p") {
    return <p className={combinedClasses}>{children}</p>
  }
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  tone: PropTypes.oneOf([
    "neutral",
    "subtle",
    "positive",
    "critical",
    "caution",
    "critical",
  ]),
  weight: PropTypes.oneOf(["regular", "medium", "strong"]),
  align: PropTypes.oneOf(["left", "center", "right"]),
  size: PropTypes.oneOf([
    "base",
    "sm",
    "lg",
    "xl",
    "2xl",
    "3xl",
    "4xl",
    "5xl",
    "6xl",
  ]),
  component: PropTypes.oneOf(["span", "p"]),
}
