import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { useState } from "react"
import { Typography } from "@harmony"
import {
  EVENT_PAYMENT,
  useCheckout,
  useEventTracker,
  usePaymentService,
  useReservation,
} from "modules/checkout/hooks"
import { PaymentIntentSchema } from "./types"

export function StripeWalletPaymentForm({
  onPaymentConfirmation,
  onPaymentError,
}) {
  const stripe = useStripe()
  const elements = useElements()
  const { returnUrl, state } = useCheckout()
  const paymentService = usePaymentService()
  const [isWalletAvailable, setWalletAvailable] = useState(false)
  const { reservation } = useReservation()
  const { onEvent } = useEventTracker()

  const onSubmit = async () => {
    try {
      const form = await elements.submit()

      if (form.error) {
        throw new Error(form.error)
      }

      const data = await paymentService.createPaymentIntent(
        reservation.reservationId,
        state,
        state.share,
        {
          email: state.user.emailAddress,
          name: state.user.fullName,
        },
      )
      const { paymentIntent } = PaymentIntentSchema.parse(data)

      if (!paymentIntent || !paymentIntent.client_secret) {
        throw new Error()
      }

      const { error, paymentIntent: confirmedPaymentIntent } =
        await stripe.confirmPayment({
          elements,
          clientSecret: paymentIntent.client_secret,
          confirmParams: {
            expand: ["payment_method"],
            return_url: returnUrl,
          },
          redirect: "if_required",
        })

      if (error) {
        throw new Error(error)
      }

      const amount = confirmedPaymentIntent.amount / 100
      const walletType =
        confirmedPaymentIntent?.payment_method?.card?.wallet?.type || "unknown"

      onEvent(EVENT_PAYMENT, {
        type: walletType,
        amount,
      })

      await onPaymentConfirmation({ paymentId: paymentIntent.id })
    } catch (error) {
      onPaymentError(error)
    }
  }

  if (!stripe || !elements) {
    return null
  }

  return (
    <div className={isWalletAvailable ? "block" : "hidden"}>
      <ExpressCheckoutElement
        onConfirm={onSubmit}
        onReady={() => {
          setWalletAvailable(true)
        }}
        onNotReady={() => {
          setWalletAvailable(false)
        }}
        options={{
          buttonType: { googlePay: "plain", applePay: "plain" },
          paymentMethods: { googlePay: "always", applePay: "always" },
          buttonHeight: 48,
          buttonTheme: { googlePay: "black", applePay: "black" },
        }}
      />
      <Typography className="text-lg font-bold mt-4">OR</Typography>
    </div>
  )
}
