import { Heading, Icon } from "@harmony"

export function Error({ heading, children }) {
  return (
    <section className="bg-harmony-surface-base dark:bg-harmony-surface-base-dark text-harmony-text-base dark:text-harmony-text-base-dark w-[100vw] h-[100vh] flex flex-col items-center justify-center">
      <section className="flex flex-col gap-6 rounded-2xl bg-harmony-surface-level-1 dark:bg-harmony-surface-level-1-dark p-6">
        <div className="flex flex-col gap-4 items-center">
          <Icon name="XCircle" className="w-12 h-12" />
          <Heading>{heading}</Heading>
        </div>
        {children}
      </section>
    </section>
  )
}
