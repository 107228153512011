import { Field, InputBase } from "@harmony"
import { forwardRef, useEffect, useId, useRef, useState } from "react"
import { getLabel, getSelectedValueIndex, getValue } from "./utils"
import { Items, ToggleIcon } from "./parts"
import { useKeyboardNavigation } from "./hooks"

const Dropdown = forwardRef(
  (
    {
      label = "",
      helperText = "",
      helperType,
      defaultValue = "",
      placeholder = "Select",
      items = [],
      onChange = () => {},
      onBlur = () => {},
      onFocus = () => {},
      isDisabled = false,
      shouldAutoFocus = false,
    },
    _ref,
  ) => {
    const ref = useRef()
    const fieldId = useId()

    const blurTimeoutRef = useRef(null)
    const selectionTimeoutRef = useRef(null)

    const [isFocused, setFocused] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [selectedValue, setSelected] = useState(defaultValue)

    const onSelection = (value) => {
      const selectedIndex = getSelectedValueIndex(items, value)

      if (selectedIndex === -1) {
        return
      }

      setSelected(value)
      setHighlightedIndex(selectedIndex)

      clearTimeout(selectionTimeoutRef.current)
      selectionTimeoutRef.current = setTimeout(() => {
        setOpen(false)
      }, 250)

      onChange(value)
    }

    const { onKeyDown, highlightedIndex, setHighlightedIndex } =
      useKeyboardNavigation({
        items,
        isOpen,
        setOpen,
        onSelection,
      })

    const onClick = (e) => {
      const isFocusEvent = e.target.tagName === "INPUT"

      if (isFocusEvent || isDisabled) {
        return
      }

      setOpen(!isOpen)
    }

    const _onFocus = () => {
      if (blurTimeoutRef.current) {
        clearTimeout(blurTimeoutRef.current)
      }

      setFocused(true)
      onFocus?.()
    }

    const _onBlur = () => {
      blurTimeoutRef.current = setTimeout(() => {
        setOpen(false)
        setFocused(false)
        onBlur?.()
      }, 200)
    }

    useEffect(() => {
      if (isOpen) {
        const selectedIndex = getSelectedValueIndex(items, selectedValue)
        setHighlightedIndex(selectedIndex)
      }
    }, [isOpen])

    const selectClasses = ["select-none"]

    const selectedIndex = getSelectedValueIndex(items, selectedValue)

    return (
      <Field
        fieldId={fieldId}
        label={label}
        helperText={helperText}
        helperType={helperType}
      >
        <div className={selectClasses.join(" ")} onClick={onClick}>
          <InputBase
            isFocused={isFocused}
            ref={ref}
            hasError={helperType === "critical"}
          >
            <div className="flex justify-between w-full">
              <span
                className="text-base text-components-text-base"
                data-testid="opener"
              >
                {getLabel(
                  items,
                  getValue(defaultValue, selectedValue),
                  placeholder,
                )}
              </span>
              <ToggleIcon isOpen={isOpen} />
            </div>

            <input
              id={fieldId}
              role="combobox"
              type="text"
              onFocus={_onFocus}
              onBlur={_onBlur}
              onKeyDown={onKeyDown}
              className="sr-only"
              disabled={isDisabled}
              autoFocus={shouldAutoFocus}
              readOnly
              aria-haspopup="listbox"
              aria-expanded={isOpen}
              aria-controls={isOpen ? `${fieldId}-list` : undefined}
              aria-activedescendant={
                highlightedIndex >= 0
                  ? `${fieldId}-${highlightedIndex}`
                  : undefined
              }
              aria-describedby={`${fieldId}-helper`}
            />

            <Items
              fieldId={fieldId}
              items={items}
              isOpen={isOpen}
              onClose={() => setOpen(false)}
              onChange={onSelection}
              parentRef={ref}
              selectedIndex={selectedIndex}
              highlightedIndex={highlightedIndex}
            />
          </InputBase>
        </div>
      </Field>
    )
  },
)

Dropdown.displayName = "Dropdown"

export { Dropdown }
