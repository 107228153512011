import { Icon } from "@harmony"
import PropTypes from "prop-types"
import { forwardRef } from "react"
import { iconSizes } from "../../Icon/iconSizes"
import { buttonVariantClasses } from "../buttonVariantClasses"

const buttonSizeClasses = {
  default: ["px-6", "py-3", "gap-2", "h-12", "text-base"],
  small: ["px-4", "py-2", "gap-1", "h-9", "text-sm"],
}

/**
 * ButtonLink - Looks like a button but renders a semantic link.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Text or content inside the button.
 * @param {Function} [props.onClick] - Callback for single-click events.
 * @param {"outline" | "solid" | "soft" | "transparent"} [props.variant="outline"] - Visual style of the button.
 * @param {"default" | "small"} [props.size="default"] - Size of the button.
 * @param {"neutral" | "brand"} [props.tone="neutral"] - Tone of the button.
 * @param {string} [props.endIcon] - Icon displayed at the end of the button.
 * @param {string} [props.startIcon] - Icon displayed at the start of the button.
 * @param {boolean} [props.isFullWidth=false] - Whether the button is full width.
 */

const ButtonLink = forwardRef(
  (
    {
      children,
      href,
      variant = "outline",
      size = "default",
      tone = "neutral",
      endIcon = null,
      startIcon = null,
      isFullWidth = false,
      ...props
    },
    ref,
  ) => {
    const variantClasses = buttonVariantClasses[variant][tone]
    const sizeClasses = buttonSizeClasses[size]

    const buttonClasses = [
      "flex",
      "justify-center",
      "items-center",
      "rounded-full",
      "font-semibold",
      ...variantClasses,
      ...sizeClasses,
    ]

    if (isFullWidth) {
      buttonClasses.push("w-full")
    }

    const iconSize = iconSizes[size]

    return (
      <a href={href} className={buttonClasses.join(" ")} ref={ref} {...props}>
        {startIcon && <Icon name={startIcon} size={iconSize} />}
        {children}
        {endIcon && (
          <Icon
            name={endIcon}
            size={iconSize}
            className={`harmony-button-${variant}-${tone}-fg dark:harmony-button-${variant}-${tone}-fg-dark`}
          />
        )}
      </a>
    )
  },
)

ButtonLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  href: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["outline", "solid", "soft", "transparent"]),
  size: PropTypes.oneOf(["default", "small"]),
  tone: PropTypes.oneOf(["neutral", "brand"]),
  endIcon: PropTypes.string,
  startIcon: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
}

ButtonLink.displayName = "Button"

export { ButtonLink }
