import { InvoiceContext } from "./InvoiceContext"
import { useQuery } from "@tanstack/react-query"
import { useLoading, useReservation } from "../hooks"
import { BillNotFound, InvoiceNotFound } from "../parts"
import { BillService } from "../api"

const billService = new BillService()

export function InvoiceProvider({ children }) {
  const { setLoading } = useLoading()

  const { reservation } = useReservation()
  const { invoiceId } = reservation

  const { data, isLoading, isPending, isError } = useQuery({
    queryKey: ["bill-get-by-invoice-id", invoiceId],
    queryFn: async () => {
      setLoading(true)
      try {
        const data = await billService.getByInvoiceId(invoiceId)
        return data
      } catch (e) {
        console.error("Couldnt load incoming invoice data", e)
      } finally {
        setLoading(false)
      }
    },
    retry: 3,
    enabled: !!invoiceId,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
  })

  if (!invoiceId) {
    return <InvoiceNotFound reservationId={reservation.reservationId} />
  }

  if (isError) {
    return <BillNotFound reservationId={reservation.reservationId} />
  }

  if (isLoading || isPending) {
    return null
  }

  const {
    reservationCode,
    restaurantLocation,
    server,
    dateTime,
    table,
    receiptNum,
    items,
    guestEmail,
    firstName,
    lastName,

    total,
  } = data

  const invoice = {
    reservationCode,
    restaurantLocation,
    dateTime,
    items,
    server,
    table,
    receiptNum,
    guestEmail,
    firstName,
    lastName,
    total,
  }

  return (
    <InvoiceContext.Provider
      value={{
        invoice,
        isLoading: isLoading || isPending,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  )
}
