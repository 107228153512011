import PropTypes from "prop-types"

export function Image({ src, alt }) {
  return (
    <img
      src={src}
      alt={alt}
      className="aspect-16/9 object-cover w-full h-32 rounded-2xl"
    />
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}
