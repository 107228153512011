import { PostHogProvider as PostHogProviderBase } from "posthog-js/react"

import { POSTHOG_CONFIG } from "../config/posthog"

/**
 * A wrapper component for PostHog analytics integration.
 * Only initializes PostHog if configuration is present, otherwise renders children directly.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children - Child components to be wrapped
 * @returns {React.ReactNode} PostHog provider wrapped children or children directly
 */
export const PostHogProvider = ({ children }) => {
  if (!POSTHOG_CONFIG) {
    return children
  }

  const { apiKey, options } = POSTHOG_CONFIG

  return (
    <PostHogProviderBase apiKey={apiKey} options={options}>
      {children}
    </PostHogProviderBase>
  )
}
