import { Button, Divider, Heading, Icon, Stack, Watermark } from "@harmony"
import { useTenantConfig } from "@hooks"
import { Outlet, useLocation } from "react-router"

import {
  EVENT_PAGE_VISIT,
  EVENT_SUBMIT_EMAIL_RECEIPT,
  EVENT_SUBMIT_REPORT_PROBLEM,
  useCheckout,
  useEventTracker,
  useInvoice,
  useNavigateExtended,
  useReservation,
  useRestaurant,
} from "modules/checkout/hooks"

import {
  Accordion,
  Affiliates,
  Header,
  PaymentStatus,
  ReportProblem,
  ReservationDetails,
  Rewards,
  SocialMedia,
  TicketItems,
  TicketTotal,
  Venue,
} from "./components"

import { BillService } from "modules/checkout/api"
import { useEffect } from "react"
import { Routes } from "../.."
import { SendReceipt } from "./components/SendReceipt"

const billService = new BillService()

export function LiveBill() {
  const { reservation } = useReservation()
  const { restaurant } = useRestaurant()
  const { invoice } = useInvoice()
  const { rewards, affiliates, taxRate, taxTerm, serviceRate } =
    useTenantConfig()
  const { state } = useCheckout()
  const navigate = useNavigateExtended()
  const { onEvent } = useEventTracker()
  const location = useLocation()

  useEffect(() => {
    onEvent(EVENT_PAGE_VISIT)
  }, [location])

  function onSettleBill() {
    if (state.forceSplitBy) {
      return navigate.append(Routes.SettleBill)
    }

    return navigate.append(Routes.SplitBill)
  }

  function onShareBill() {
    return navigate.append(Routes.ShareBill)
  }

  function onReportProblem(message) {
    onEvent(EVENT_SUBMIT_REPORT_PROBLEM)
    return billService.reportProblem(reservation.reservationId, message)
  }

  function onSendReceipt(email) {
    onEvent(EVENT_SUBMIT_EMAIL_RECEIPT)
    return billService.sendReceipt(email, reservation.invoiceId)
  }

  const isBillEmpty = invoice.items.length === 0
  const hasMadePayment = state.payments.length > 0

  const shouldShowSettleYourShareButton =
    !state.hasSettledBill &&
    !hasMadePayment &&
    state.isPayingSplit &&
    !state.forceSplitBy

  const shouldShowSettleBillButton =
    !shouldShowSettleYourShareButton && !state.hasSettledBill && !hasMadePayment

  const shouldShowShareBillButton =
    !shouldShowSettleYourShareButton && !state.hasSettledBill && hasMadePayment

  const numberOfPaidGuests = state.forceSplitBy
    ? 0
    : state.splitBy - state.remainingSplits

  if (isBillEmpty) {
    return (
      <div className="relative flex flex-col items-center flex-1 w-full px-5 py-4 overflow-hidden gap-y-4 lg:pb-11 bg-harmony-surface-base">
        <Header
          user={state.user}
          dateTime={invoice.dateTime}
          hasSettledBill={state.hasSettledBill}
          hasPartiallyPaid={state.hasPartiallyPaid}
        />

        <div className="w-full">
          <div className="flex flex-col items-center justify-center w-full py-5 gap-y-6">
            <Divider />
            <Icon name="Receipt" className="w-12 h-12" />
            <Heading level={3}>Your bill is empty at the moment</Heading>
            <p>As you order dishes, you will see them added here.</p>
            <Divider />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="relative flex flex-col items-center flex-1 w-full pb-4 bg-harmony-surface-base dark:bg-harmony-surface-base-dark">
      <Watermark variant="icon">
        <div className="px-6">
          <Stack space="8">
            {!shouldShowSettleYourShareButton && (
              <Header
                dateTime={invoice.dateTime}
                hasMadePayment={hasMadePayment}
                isPayingFull={state.isPayingFull}
                isPayingSplit={state.isPayingSplit}
                hasSettledBill={state.hasSettledBill}
                payments={state.payments}
                user={state.user}
              />
            )}

            <Stack>
              <PaymentStatus
                hasMadePayment={hasMadePayment}
                hasSettledBill={state.hasSettledBill}
                isPayingFull={state.isPayingFull}
                isPayingSplit={state.isPayingSplit && !state.forceSplitBy}
                numberOfPaidGuests={numberOfPaidGuests}
                numberOfUnpaidGuests={state.remainingSplits}
                splitBy={state.splitBy}
                total={state.total}
                collected={state.collected}
                user={state.user}
              />

              {shouldShowShareBillButton && (
                <Button
                  startIcon="ShareFat"
                  onClick={onShareBill}
                  className="mb-6"
                  isFullWidth
                >
                  Share the bill
                </Button>
              )}
            </Stack>

            {shouldShowSettleYourShareButton && (
              <Button variant="solid" isFullWidth onClick={onSettleBill}>
                Settle your share
              </Button>
            )}
          </Stack>

          <TicketItems items={invoice.items} />

          <TicketTotal
            hasMadePayment={hasMadePayment}
            isPayingFull={state.isPayingFull}
            isPayingSplit={state.isPayingSplit}
            payments={state.payments}
            serviceRate={serviceRate}
            totals={invoice.total}
            taxRate={taxRate}
            taxTerm={taxTerm}
          />

          {state.hasSettledBill && <Rewards rewards={rewards} />}

          {shouldShowSettleBillButton && (
            <Button
              variant="solid"
              isFullWidth
              onClick={onSettleBill}
              className="!mt-4 mb-4"
            >
              Settle the bill
            </Button>
          )}

          {state.hasSettledBill && (
            <div className="mt-4 mb-4">
              <ReportProblem onSubmit={onReportProblem} />
            </div>
          )}

          {hasMadePayment && <SendReceipt onSubmit={onSendReceipt} />}

          <Outlet />

          <Accordion>
            <ReservationDetails
              server={invoice.server}
              dateTime={invoice.dateTime}
              table={invoice.table}
              reservationCode={invoice.reservationCode}
              receiptNum={invoice.receiptNum}
            />
          </Accordion>

          <Affiliates affiliates={affiliates} />
          <SocialMedia social={restaurant.social} />
          <Venue
            name={restaurant.name}
            email={restaurant.email}
            location={invoice.restaurantLocation}
            phoneNumber={restaurant.phone_number}
            vatNumber={restaurant.vat_number}
          />
        </div>
      </Watermark>
    </div>
  )
}
