import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Button, Loader, Stack } from "@harmony"
import { useState } from "react"
import {
  EVENT_PAYMENT,
  useCheckout,
  useEventTracker,
} from "modules/checkout/hooks"

export function StripeCardPaymentForm({
  paymentIntent,
  onPaymentConfirmation,
  onPaymentError,
}) {
  const stripe = useStripe()
  const elements = useElements()
  const { returnUrl, state } = useCheckout()
  const { onEvent } = useEventTracker()

  const [isLoading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)

    try {
      const form = await elements.submit()

      if (form.error) {
        throw new Error(form.error)
      }

      const { error, paymentIntent: confirmedPaymentIntent } =
        await stripe.confirmPayment({
          elements,
          clientSecret: paymentIntent.client_secret,
          confirmParams: {
            return_url: returnUrl,
          },
          redirect: "if_required",
        })

      if (error) {
        throw new Error(error)
      }

      const amount = confirmedPaymentIntent.amount / 100

      onEvent(EVENT_PAYMENT, {
        type: "card",
        amount,
      })

      await onPaymentConfirmation({ paymentId: paymentIntent.id })
    } catch (error) {
      onPaymentError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Stack space="6">
      <PaymentElement
        options={{
          wallets: {
            googlePay: "never",
            applePay: "never",
          },
        }}
      />

      <Button
        variant="solid"
        isFullWidth
        onClick={onSubmit}
        isDisabled={isLoading}
      >
        {isLoading ? <Loader /> : `Pay ${state.share.total}`}
      </Button>
    </Stack>
  )
}
