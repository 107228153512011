import { Button, Heading, Icon, Stack, Text, TextLink } from "@harmony"

import { PRIVACY_URL } from "../../../config/staticUrls"

import SlideUpPanel from "../SlideUpPanel"
import { useCookieConsent } from "./hooks/useCookieConsent"

const HIDE_COOKIE_NOTICE = process.env.REACT_APP_HIDE_COOKIE_NOTICE === "true"

/**
 * A drawer component that handles cookie consent preferences.
 * Displays a modal drawer with options to accept or reject analytics cookies.
 * The drawer is shown only when consent status is "undecided".
 *
 * @component
 * @returns {JSX.Element} The CookieNotice component
 */

const CookieNotice = () => {
  const { consentGiven, acceptCookies, rejectCookies } = useCookieConsent()

  if (HIDE_COOKIE_NOTICE) {
    return null
  }

  return (
    <SlideUpPanel
      ariaLabel="Cookie consent"
      open={consentGiven === "undecided"}
    >
      <Stack space="4">
        <Stack space="2">
          <div className="flex items-center gap-2">
            <Icon name="Cookie" />
            <Heading level={4}>Cookies</Heading>
          </div>
          <Text>
            We use cookies to improve your experience and for security purposes.
          </Text>
          <TextLink
            className="font-semibold"
            href={PRIVACY_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </TextLink>
        </Stack>
        <div className="flex justify-end w-full gap-3">
          <Button
            size="small"
            variant="solid"
            tone="neutral"
            onClick={acceptCookies}
          >
            Accept
          </Button>
          <Button
            size="small"
            variant="outline"
            tone="neutral"
            onClick={rejectCookies}
          >
            Reject
          </Button>
        </div>
      </Stack>
    </SlideUpPanel>
  )
}

export default CookieNotice
