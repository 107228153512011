import { getCookieConsent } from "../components/custom/CookieNotice"

const HIDE_COOKIE_NOTICE = process.env.REACT_APP_HIDE_COOKIE_NOTICE === "true"
const POSTHOG_ENABLED = process.env.REACT_APP_POSTHOG_ENABLED === "true"
const POSTHOG_API_KEY = process.env.REACT_APP_POSTHOG_API_KEY
const POSTHOG_API_HOST = process.env.REACT_APP_POSTHOG_API_HOST
const PROD_ENV = process.env.NODE_ENV === "production"

/**
 * PostHog configuration object
 * @type {Object|null}
 *
 * Returns null when PostHog is disabled, otherwise returns configuration object with:
 * - apiKey: The PostHog API key for authentication
 * - options: Additional PostHog client configuration
 *   - api_host: The PostHog API server URL
 *   - debug: Debug mode enabled in non-production environments
 */
export const POSTHOG_CONFIG = POSTHOG_ENABLED
  ? {
      apiKey: POSTHOG_API_KEY,
      options: {
        api_host: POSTHOG_API_HOST,
        debug: !PROD_ENV,
        persistence:
          !HIDE_COOKIE_NOTICE && getCookieConsent() === "yes"
            ? "localStorage+cookie"
            : "memory",
      },
    }
  : null
