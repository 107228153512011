import { useQuery } from "@tanstack/react-query"
import { RestaurantService } from "../api"
import { useLoading, useReservation } from "../hooks"
import { BillNotFound, RestaurantShutdown } from "../parts"
import { RestaurantContext } from "./RestaurantContext"

const restaurantService = new RestaurantService()

export function RestaurantProvider({ children }) {
  const { setLoading } = useLoading()
  const { reservation } = useReservation()
  const { tenantId, subtenantId } = reservation

  const { data, isLoading, isPending, isError } = useQuery({
    queryKey: ["restaurant-get", tenantId, subtenantId],
    queryFn: async () => {
      setLoading(true)
      try {
        const data = await restaurantService.get(tenantId, subtenantId)
        return data
      } catch (e) {
        console.error("Couldnt load incoming restaurant data", e)
      } finally {
        setLoading(false)
      }
    },
    retry: 3,
    enabled: !!tenantId,
  })

  if (isError) {
    return <BillNotFound reservationId={reservation.reservationId} />
  }

  if (isLoading || isPending) {
    return null
  }

  const {
    email,
    name,
    vat_number,
    social,
    emergency_shutoff,
    payment_gateway,
    phone_number,
  } = data

  const restaurant = {
    email,
    name,
    phone_number,
    vat_number,
    social,
    payment_gateway,
  }

  if (emergency_shutoff) {
    return <RestaurantShutdown />
  }

  return (
    <RestaurantContext.Provider value={{ restaurant }}>
      {children}
    </RestaurantContext.Provider>
  )
}
