import { Children, cloneElement, isValidElement, useState } from "react"
import { Body, Header, Option } from "./parts"

/**
 * Renders an accordion container that manages which child item is currently open.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children - The child elements (Accordion.Option) to be rendered.
 */

export function Accordion({ children }) {
  const [openIndex, setOpenIndex] = useState(null)

  const toggleIndex = (index) => {
    setOpenIndex((prev) => (prev === index ? null : index))
  }

  const items = Children.map(children, (child, index) => {
    if (!isValidElement(child)) {
      return child
    }

    if (child.type !== Option) {
      const childName = child.type?.displayName || child.type?.name || "Unknown"

      // eslint-disable-next-line no-console
      console.error(
        `Accordion child at index ${index} must be <Accordion.Option>, but got <${childName}>.`,
      )
      return child
    }

    return cloneElement(child, {
      itemIndex: index,
      openIndex,
      toggleIndex,
    })
  })

  return <div className="flex flex-col gap-8">{items}</div>
}

Accordion.Body = Body
Accordion.Header = Header
Accordion.Option = Option
