import { Icon, Spread, Stack, Text } from "@harmony"

export function VisualSplitIndicator({ numberOfPaidGuests, splitBy }) {
  return (
    <div className="flex flex-row gap-1 justify-center flex-wrap m-auto">
      {Array.from({ length: splitBy }).map((_, index) => {
        const hasBeenSettled = index < numberOfPaidGuests

        if (hasBeenSettled) {
          return (
            <Icon
              key={`guest_${index}`}
              name="UserCircleCheck"
              size="32"
              className="fill-harmony-positive-500 dark:fill-harmony-positive-500"
            />
          )
        }

        return (
          <Icon
            key={`guest_${index}`}
            name="UserCircle"
            size="32"
            className="fill-harmony-icon-subtle dark:fill-harmony-icon-subtle-dark"
          />
        )
      })}
    </div>
  )
}

export function PaymentStatus({
  isPayingSplit,
  isPayingFull,
  hasMadePayment,
  hasSettledBill,
  splitBy,
  numberOfPaidGuests,
  numberOfUnpaidGuests,
  total,
  collected,
  user,
}) {
  if (hasSettledBill && isPayingFull) {
    return null
  }

  if (hasSettledBill) {
    return (
      <div className="mb-4">
        <Stack>
          <Text size="xl" weight="strong" align="center">
            Your guests have all paid
          </Text>
          <Icon
            name="UserCircleCheck"
            size="48"
            className="m-auto fill-harmony-positive-500 dark:fill-harmony-positive-500"
          />
        </Stack>
      </div>
    )
  }

  if (!hasMadePayment && isPayingSplit) {
    return (
      <div>
        <Stack space="3">
          <Text size="2xl" weight="strong" align="center">
            Split bill
          </Text>

          <VisualSplitIndicator
            numberOfPaidGuests={numberOfPaidGuests}
            splitBy={splitBy}
          />

          <Spread>
            <Stack space="0">
              <Text size="2xl" weight="strong" align="center">
                {collected.total}
              </Text>
              <Text size="sm" align="center" tone="subtle">
                paid
              </Text>
            </Stack>

            <Stack space="0">
              <Text size="2xl" weight="strong" align="center">
                {total.total}
              </Text>

              <Text size="sm" align="center" tone="subtle">
                to pay
              </Text>
            </Stack>
          </Spread>
        </Stack>
      </div>
    )
  }

  if (hasMadePayment && isPayingSplit) {
    return (
      <div>
        <Stack>
          {user.isOwner && (
            <Text size="xl" weight="strong" align="center">
              Your guests
            </Text>
          )}

          <VisualSplitIndicator
            numberOfPaidGuests={numberOfPaidGuests}
            splitBy={splitBy}
          />

          <Stack>
            <Text size="5xl" align="center">
              {numberOfUnpaidGuests}
            </Text>
            <Text tone="subtle" align="center">
              {numberOfUnpaidGuests > 1 ? "guests" : "guest"} still to pay{" "}
              <Text weight="strong" tone="subtle">
                ({total.total})
              </Text>
            </Text>
          </Stack>
        </Stack>
      </div>
    )
  }

  return null
}
