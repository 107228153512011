import { Button, Divider, Heading, Skeleton, Stack, Text } from "@harmony"
import QRCode from "react-qr-code"
import { useCheckout, useNavigateExtended, useReservation } from "../../hooks"
import { ModalControls } from "../../parts"

import { useQuery } from "@tanstack/react-query"
import { ReservationService } from "modules/checkout/api"

const reservationService = new ReservationService()

export function useShareUrl() {
  const { reservation } = useReservation()
  const { reservationId } = reservation

  const query = useQuery({
    queryKey: ["shareUrl", reservationId],
    queryFn: () =>
      reservationService.getReservationSharingInformation(reservationId),
    enabled: Boolean(reservationId),
    retry: 1,
  })

  return {
    link: query.data,
    isLoading: query.isPending || query.isLoading,
  }
}

export function PassBill({
  title = "Pass the bill",
  description = "Use the options below to pass the bill to another guest.",
  onNavigateBack,
}) {
  const { onEndFlow } = useCheckout()
  const { link, isLoading } = useShareUrl()
  const navigate = useNavigateExtended()

  async function onShareWithApp() {
    if (navigator.share) {
      try {
        await navigator.share({
          url: link,
        })
      } catch (error) {
        console.error("Error sharing", error)
      }
    } else {
      console.error("Web Share API not supported on this device.")
    }
  }

  return (
    <>
      <ModalControls
        onNavigateBack={() => {
          if (onNavigateBack) {
            onNavigateBack()
          } else {
            navigate.previous()
          }
        }}
        onClose={onEndFlow}
      />
      <Stack>
        <Heading level={2}>{title}</Heading>

        <Text>{description}</Text>

        {isLoading ? (
          <Skeleton className="mb-6 w-full h-12 rounded-full" />
        ) : (
          <Button
            variant="solid"
            startIcon="ShareFat"
            onClick={onShareWithApp}
            className="mb-6"
            isFullWidth
          >
            Share
          </Button>
        )}

        <Divider className="mb-6" />

        <Text align="center">Or your guests can scan the QR code</Text>

        {isLoading ? (
          <Skeleton className=" w-32 h-32 m-auto" />
        ) : (
          <QRCode
            value={link}
            className="bg-harmony-surface-base dark:bg-harmony-surface-base-dark w-32 h-32 m-auto"
          />
        )}
      </Stack>
    </>
  )
}
