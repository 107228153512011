import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import moment from "moment-timezone"
import React, { Suspense, useEffect } from "react"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import ScrollToTop from "./components/custom/ScrollToTop"
import EnvironmentIdentifier from "./components/EnvironmentIdentifier"
import PublicRoute from "./components/router/PublicRoute"
import { useCleanParams } from "./hooks"

import { CheckoutRouting } from "modules/checkout"

const Home = React.lazy(() => import("./modules/Home"))
const BookingInfo = React.lazy(() => import("./modules/bookingInfo"))
const CalenderInfo = React.lazy(
  () => import("./modules/bookingInfo/CalenderInfo"),
)
const AvailabilityInfo = React.lazy(
  () => import("./modules/bookingInfo/AvailabilityInfo"),
)
const InformationInfo = React.lazy(
  () => import("./modules/bookingInfo/InformationInfo"),
)
const ConfirmInfo = React.lazy(
  () => import("./modules/bookingInfo/ConfirmInfo"),
)

const Reservations = React.lazy(() => import("./modules/reservations"))
const GuestInformation = React.lazy(
  () => import("./modules/reservations/information"),
)
const GuestConfirm = React.lazy(() => import("./modules/reservations/confirm"))

const ModifyReservation = React.lazy(
  () => import("./modules/modifyReservation"),
)

const ModifyInformation = React.lazy(
  () => import("./modules/modifyReservation/ModifyInformation"),
)

const ModifyCalender = React.lazy(
  () => import("./modules/modifyReservation/ModifyCalender"),
)

const ModifyAvailability = React.lazy(
  () => import("./modules/modifyReservation/ModifyAvailability"),
)

const ModifyCancelled = React.lazy(
  () => import("./modules/modifyReservation/ModifyCancelledPage"),
)

const FrequentlyAskedQuestions = React.lazy(
  () => import("./modules/sideMenu/FrequentlyAskedQuestions"),
)
const TermsAndConditions = React.lazy(
  () => import("./modules/sideMenu/TermsAndConditions"),
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 30000,
      cacheTime: 3600000,
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
})

moment.tz.setDefault("Europe/London")

function App() {
  const ENV = process.env.REACT_APP_ENVIRONMENT

  useCleanParams()

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]')
    if (metaThemeColor) {
      metaThemeColor.setAttribute(
        "content",
        ENV === "production" ? "#18243a" : "#EE4B2B",
      )
    }
  }, [ENV])

  return (
    <Router>
      <div className="min-h-screen bg-harmony-surface-level-1 dark:bg-harmony-surface-level-1-dark text-harmony-text-base dark:text-harmony-text-base-dark">
        <ScrollToTop />
        <EnvironmentIdentifier />
        <QueryClientProvider client={queryClient}>
          <Suspense>
            <Routes>
              {/* Home route */}
              <Route path="/" element={<PublicRoute />}>
                <Route index element={<Home />} />
                <Route path="*" element={<Home />} />
              </Route>

              {/* Booking info routes */}
              <Route
                path="/booking-info/:tenantId/:subtenantId?"
                element={<BookingInfo />}
              >
                <Route index element={<CalenderInfo />} />
                <Route path="availability" element={<AvailabilityInfo />} />
                <Route path="information" element={<InformationInfo />} />
                <Route path="confirm" element={<ConfirmInfo />} />
              </Route>

              {/* Reservations routes */}
              <Route
                path="/reservations/:tenantId/:subtenantId?/:bookingID"
                element={<Reservations />}
              >
                <Route index element={<GuestInformation />} />
                <Route path="confirmed" element={<GuestConfirm />} />
              </Route>

              {/* Modify Reservation routes */}
              <Route
                path="/modify-reservation/:tenantId/:subtenantId?/:bookingID"
                element={<ModifyReservation />}
              >
                <Route index element={<ModifyInformation />} />
                <Route path="calender" element={<ModifyCalender />} />
                <Route path="availability" element={<ModifyAvailability />} />
                <Route path="cancelled" element={<ModifyCancelled />} />
              </Route>

              {CheckoutRouting()}

              {/* Static pages */}
              <Route
                path="/frequently-asked-questions"
                element={<FrequentlyAskedQuestions headerSpace />}
              />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions headerSpace />}
              />
            </Routes>
          </Suspense>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            closeOnClick
            draggable
            progress={undefined}
            theme="dark"
          />
        </QueryClientProvider>
      </div>
    </Router>
  )
}

export { App }
