import { Divider, Panel, Spread, Stack, Text } from "@harmony"
import { TipType } from "../../../../api"

function RowTotal({ amount }) {
  return (
    <Spread space="2">
      <Text size="xl" weight="medium">
        Bill total
      </Text>
      <Text size="2xl" weight="medium">
        {amount}
      </Text>
    </Spread>
  )
}

function RowSubtotal({ amount }) {
  return (
    <Spread space="2">
      <Text weight="medium">Subtotal</Text>
      <Text weight="medium">{amount}</Text>
    </Spread>
  )
}

function RowTax({ taxRate, taxTerm, amount }) {
  return (
    <Text>
      includes {taxRate * 100}% {taxTerm} ({amount})
    </Text>
  )
}

function RowServiceCharge({ serviceRate, amount }) {
  return (
    <Spread space="2">
      <Text>Service charge ({serviceRate * 100}%)</Text>
      <Text weight="medium">{amount}</Text>
    </Spread>
  )
}

function RowTip({ tip, amount }) {
  return (
    <>
      {tip.tipType === TipType.Fixed && (
        <Spread space="2">
          <Text>Additional tip</Text>
          <Text weight="medium">{amount}</Text>
        </Spread>
      )}

      {tip.tipType === TipType.Percent && (
        <Spread space="2">
          <Text>Additional tip ({tip.tipAmount * 100}%)</Text>
          <Text weight="medium">{amount}</Text>
        </Spread>
      )}
    </>
  )
}

function Paid({ totals, taxRate, taxTerm, serviceRate, payment, children }) {
  const { total } = totals

  const hasTipped = payment.tip.tipAmount > 0

  return (
    <Panel background="transparent">
      <Stack space="2">
        <RowTotal amount={total} />

        <Divider className="mb-1" />

        {children}

        <div className="mb-1">
          <Stack space="2">
            <RowSubtotal amount={payment.allocation.subtotal} />
            <RowTax
              taxRate={taxRate}
              taxTerm={taxTerm}
              amount={payment.allocation.tax}
            />
            <RowServiceCharge
              serviceRate={serviceRate}
              amount={payment.allocation.service}
            />

            {hasTipped && (
              <RowTip tip={payment.tip} amount={payment.allocation.tip} />
            )}
          </Stack>
        </div>

        <Stack space="2">
          <Divider />

          <Spread space="2">
            <Text size="xl" weight="medium">
              You paid
            </Text>
            <Text size="2xl" weight="medium">
              {payment.paid}
            </Text>
          </Spread>
        </Stack>
      </Stack>
    </Panel>
  )
}

function NotPaid({ totals, taxRate, taxTerm, serviceRate }) {
  const { total, subtotal, tax, service } = totals

  return (
    <Panel background="transparent">
      <Stack space="2">
        <RowSubtotal amount={subtotal} />
        <RowTax taxRate={taxRate} taxTerm={taxTerm} amount={tax} />
        <RowServiceCharge serviceRate={serviceRate} amount={service} />
        <Divider />
        <RowTotal amount={total} />
      </Stack>
    </Panel>
  )
}

export function TicketTotal({
  hasMadePayment,
  isPayingSplit,
  isPayingFull,
  payments,
  ...props
}) {
  const payment = payments[payments.length - 1]
  const amountOfSplitPaid = payment && payment.allocation.splitSize

  switch (true) {
    case hasMadePayment && isPayingSplit:
      return (
        <Paid {...props} payment={payment}>
          <div className="-mt-1 mb-1">
            <Text size="xl" weight="medium">
              Your payment ({amountOfSplitPaid}{" "}
              {amountOfSplitPaid > 1 ? "guests" : "guest"})
            </Text>
          </div>
        </Paid>
      )

    case hasMadePayment && isPayingFull:
      return <Paid {...props} payment={payment} />
  }

  return <NotPaid {...props} />
}
