import {
  Icon,
  useAccordionContext,
  buildAccordionHeaderProps,
  Heading,
} from "@harmony"

/**
 * Displays a rotating caret icon
 */

function ToggleIcon() {
  const { isOpen } = useAccordionContext()
  const classes = ["transition-transform", "duration-200", "ease-in-out"]

  if (isOpen) {
    classes.push("rotate-180")
  }

  return (
    <div className="h-[24px] w-[24px] overflow-hidden">
      <Icon name="CaretDown" className={classes.join(" ")} />
    </div>
  )
}

/**
 * Renders a header with a toggle icon that opens or closes the accordion.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children - The header text or elements.
 */

export function Header({ children, ...props }) {
  return (
    <div
      {...buildAccordionHeaderProps()}
      className="flex justify-between"
      {...props}
    >
      <Heading level={3}>{children}</Heading>

      <ToggleIcon />
    </div>
  )
}

Header.Icon = ToggleIcon
