import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../tailwind.config.js"
import merge from "lodash.merge"

export function getThemePreset() {
  const fullConfig = resolveConfig(tailwindConfig)

  const presets = fullConfig.presets || []

  const merged = {}

  for (const preset of presets) {
    merge(merged, preset)
  }

  return merged
}
