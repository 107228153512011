const CONJUNCTIONS_EN = [
  "and",
  "as",
  "but",
  "for",
  "if",
  "in",
  "nor",
  "or",
  "v",
  "vs",
]

const CONJUNCTIONS_ES = [
  "y",
  "como",
  "con",
  "de",
  "del",
  "en",
  "es",
  "la",
  "las",
  "los",
  "para",
  "que",
  "se",
  "si",
  "sobre",
  "tras",
]

const CONJUNCTIONS_FR = [
  "et",
  "au",
  "avec",
  "de",
  "du",
  "des",
  "en",
  "la",
  "le",
  "les",
  "par",
  "que",
  "se",
  "si",
  "sur",
]

const ARTICLES_EN = ["a", "an", "the"]
const ARTICLES_ES = ["el", "la", "los", "las"]
const ARTICLES_FR = ["le", "la", "les", "leur", "l'"]

const SHORT_PREPOSITIONS_EN = [
  "at",
  "by",
  "in",
  "of",
  "off",
  "on",
  "per",
  "to",
  "up",
  "via",
  "with",
]

const SHORT_PREPOSITIONS_ES = ["a", "con", "de", "en", "por", "sin"]
const SHORT_PREPOSITIONS_FR = [
  "à",
  "au",
  "avec",
  "de",
  "du",
  "des",
  "en",
  "à la",
  "a la",
  "la",
  "le",
  "les",
  "par",
  "que",
  "se",
  "si",
  "sur",
]

const CULINARY_ABBREVIATIONS = [
  "A1", // A1 Steak (Sauce)
  "ABV", // Alcohol by Volume
  "AOC", // Appellation d'Origine Contrôlée
  "BBQ", // Barbecue
  "BLT", // Bacon, Lettuce, Tomato
  "DOC", // Denominazione di Origine Controllata
  "DOCG", // Denominazione di Origine Controllata e Garantita
  "EVOO", // Extra Virgin Olive Oil
  "G&T", // Gin and Tonic
  "HP", // Houses of Parliament (Sauce)
  "IBU", // International Bitterness Units
  "IPA", // India Pale Ale
  "KC", // Kansas City (Barbecue)
  "MSG", // Monosodium Glutamate
  "NY", // New York
  "NYC", // New York City
  "PDO", // Protected Designation of Origin
  "PGI", // Protected Geographical Indication
  "SB", // Sauvignon Blanc
  "TSG", // Traditional Speciality Guaranteed
  "TX", // Texas
  "XO", // XO Cognac (and Sauce)
]

const WORDS_TO_LOWERCASE = [
  ...new Set([
    ...CONJUNCTIONS_EN,
    ...CONJUNCTIONS_ES,
    ...CONJUNCTIONS_FR,
    ...ARTICLES_EN,
    ...ARTICLES_ES,
    ...ARTICLES_FR,
    ...SHORT_PREPOSITIONS_EN,
    ...SHORT_PREPOSITIONS_ES,
    ...SHORT_PREPOSITIONS_FR,
  ]),
]

const WORDS_TO_LOWERCASE_REGEX = new RegExp(
  "^(" + WORDS_TO_LOWERCASE.join("|") + ")$",
  "i",
)

const WORDS_TO_UPPERCASE_REGEX = new RegExp(
  "^(" + CULINARY_ABBREVIATIONS.join("|") + ")$",
  "i",
)

const ALPHANUMERIC = /([A-Za-z0-9\u00C0-\u00FF])/
const WORD_SEPARATORS = /([ :–—.-])/

export const toMenuCase = (str) => {
  // Normalize, remove multiple spaces and split the string
  const normalized = str
    .toLowerCase()
    .trim()
    .replace(/\s+/g, " ")
    .split(WORD_SEPARATORS)

  return normalized
    .map((current, index, array) => {
      // Handle culinary abbreviations
      if (current.search(WORDS_TO_UPPERCASE_REGEX) > -1) {
        return current.toUpperCase()
      }

      // Handle small words (conjunctions, articles, prepositions)
      if (
        current.search(WORDS_TO_LOWERCASE_REGEX) > -1 &&
        // Skip first and last word
        index !== 0 &&
        index !== array.length - 1 &&
        // Ignore title end and subtitle start
        array[index - 3] !== ":" &&
        array[index + 1] !== ":" &&
        // Ignore small words that start a hyphenated phrase
        (array[index + 1] !== "-" ||
          (array[index - 1] === "-" && array[index + 1] === "-")) &&
        // Always capitalize if string is very short
        array.length > 2
      ) {
        return current.toLowerCase()
      }

      // Capitalize first letter of other words
      return current.replace(ALPHANUMERIC, (match) => match.toUpperCase())
    })
    .join("")
}
