export const FREEDOMPAY_3DS_SOFT_DECLINE = "FREEDOMPAY_3DS_SOFT_DECLINE"
export const CODES_ALLOWED_3DS = ["05", "02", "06", "01"]
export const STATUS_CODES = {
  FAILED: 1,
  CHECKING_3DS: 16,
  PAYMENT_CHECK: 3,
}
export const IFRAME_TYPES = {
  APPLE_PAY: "applePay",
  GOOGLE_PAY: "googlePay",
  CARD: "card",
}
