import { Heading, Text, Divider, Spread } from "@harmony"

function JustifyBetween({ children }) {
  return <div className="flex justify-between">{children}</div>
}

export const PriceDetails = ({ total, preTipTotal, tip }) => {
  return (
    <div className="pt-4">
      <Heading level={2} className="mb-2">
        <JustifyBetween>
          <span>You will pay</span>
          <span>{total}</span>
        </JustifyBetween>
      </Heading>

      <Divider className="mb-1.5" />

      <div className="flex flex-col gap-1.5 pt-1 pb-1 mb-8">
        <Spread>
          <Text>Your share</Text>
          <Text>{preTipTotal}</Text>
        </Spread>
        <Spread>
          <Text>Additional tip</Text>
          <Text>{tip}</Text>
        </Spread>
      </div>
    </div>
  )
}
