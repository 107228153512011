import { Divider } from "@harmony"

import { toMenuCase } from "../../../../../../utils/toMenuCase"

export function TicketItem({ name, quantity, price }) {
  return (
    <>
      <ul>
        <li className="flex items-center justify-between">
          <p>
            <span className="font-semibold me-2">{quantity}</span>{" "}
            <span>{toMenuCase(name)}</span>
          </p>
          <span className="font-semibold">{price}</span>
        </li>
      </ul>
      <Divider />
    </>
  )
}
