import { ReservationContext } from "./ReservationContext"
import { useQuery } from "@tanstack/react-query"
import { useLoading } from "../hooks"
import { BillNotFound } from "../parts"
import { BillService } from "../api"

const billService = new BillService()

export function ReservationProvider({ reservationId, children }) {
  const { setLoading } = useLoading()

  const { data, isLoading, isPending, isError } = useQuery({
    queryKey: ["bill-get-by-reservation-id", reservationId],
    queryFn: async () => {
      setLoading(true)
      try {
        const data = await billService.getByReservationId(reservationId)
        return data
      } catch (e) {
        console.error("Couldnt load incoming reservation data", e)
      } finally {
        setLoading(false)
      }
    },
    retry: 3,
    enabled: !!reservationId,
  })

  if (isError) {
    return <BillNotFound reservationId={reservationId} />
  }

  if (isLoading || isPending) {
    return null
  }

  const invoiceId = data.invoiceID
  const { tenantId, subtenantId } = data.reservationData

  const reservation = { reservationId, invoiceId, tenantId, subtenantId }

  return (
    <ReservationContext.Provider value={{ reservation }}>
      {children}
    </ReservationContext.Provider>
  )
}
