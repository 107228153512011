import PropTypes from "prop-types"

/**
 * Panel component for displaying content with a consistent background and padding.
 *
 * @param {Object} props - The props for the Panel component.
 * @param {"base" | "transparent"} props.background - The background color of the panel.
 * @param {React.ReactNode} props.children - The content of the panel.
 * @param {"vertical" | "horizontal"} props.direction - The direction of the panel.
 * @param {string} props.className - The class names of the panel. Used to allow tailwind classes to be applied and avoid needed to nest other styling components.
 */
export function Panel({
  background = "base",
  direction = "vertical",
  children,
  className,
}) {
  const backgroundClasses = {
    base: "bg-harmony-surface-base dark:bg-harmony-surface-base-dark",
    transparent: "bg-transparent",
  }

  const directionClasses = {
    vertical: "flex-col",
    horizontal: "flex-row",
  }

  return (
    <div
      className={`w-full py-4 flex ${directionClasses[direction]} ${backgroundClasses[background]} ${className && className}`}
    >
      {children}
    </div>
  )
}

Panel.propTypes = {
  background: PropTypes.oneOf(["positive", "transparent"]),
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
  className: PropTypes.string,
}
