export function Radio({ label, name, value, ...props }) {
  const iconClasses = [
    "inline-block",
    "w-10",
    "h-10",
    "p-1",
    "border-2",
    "rounded-full",
    "border-harmony-border-base",
    "peer-checked:bg-harmony-icon-base",
    "peer-checked:dark:bg-harmony-icon-base-dark",
    "shadow-[inset_0_0_0_4px]",
    "shadow-harmony-surface-base",
    "dark:shadow-harmony-surface-base-dark",
    "relative",
    "z-1",
  ]

  const iconHoverClasses = [
    "inline-block",
    "w-8",
    "h-8",
    "absolute",
    "left-[6px]",
    "top-[8px]",
    "z-1",
    "rounded-full",
    "peer-hover:bg-harmony-surface-level-1",
    "peer-hover:dark:bg-harmony-surface-level-1-dark",
    "pointer-events-none",
  ]

  const focusRingClasses = [
    "inline-block",
    "w-12",
    "h-12",
    "absolute",
    "left-[-4px]",
    "top-0",
    "z-1",
    "rounded-full",
    "peer-focus:bg-harmony-border-base",
    "peer-focus:dark:bg-harmony-border-base-dark",
    "opacity-50",
  ]

  const labelClasses = [
    "text-base",
    "font-regular",
    "text-harmony-text-base",
    "dark:text-harmony-text-base-dark",
  ]

  return (
    <label className="flex items-center gap-3 py-1 cursor-pointer relative">
      <input
        type="radio"
        name={name}
        value={value}
        id={`radio-${value}`}
        className="peer sr-only"
        {...props}
      />
      <span
        className={focusRingClasses.join(" ")}
        data-testid="focus-ring"
      ></span>
      <span className={iconHoverClasses.join(" ")}></span>
      <span className={iconClasses.join(" ")} data-testid="icon"></span>
      <span className={labelClasses.join(" ")} data-testid="label">
        {label}
      </span>
    </label>
  )
}
