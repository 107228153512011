import { ButtonIcon, Icon } from "@harmony"
import PropTypes from "prop-types"

const toneToIcon = {
  positive: "CheckCircle",
  information: "Info",
  critical: "XCircle",
  caution: "WarningCircle",
}

const alertToneClasses = {
  default: {
    positive: ["bg-harmony-positive-100", "text-harmony-positive-700"],
    information: ["bg-harmony-info-100", "text-harmony-info-700"],
    critical: ["bg-harmony-critical-100", "text-harmony-critical-700"],
    caution: ["bg-harmony-caution-100", "text-harmony-caution-700"],
  },
  strong: {
    positive: ["bg-harmony-positive-700", "text-harmony-positive-50"],
    information: ["bg-harmony-info-700", "text-harmony-info-50"],
    critical: ["bg-harmony-critical-700", "text-harmony-critical-50"],
    caution: ["bg-harmony-caution-400", "text-harmony-caution-900"],
  },
}

const alertToneButtonClasses = {
  default: {
    positive: ["hover:bg-harmony-positive-50"],
    information: ["hover:bg-harmony-info-50"],
    critical: ["hover:bg-harmony-critical-50"],
    caution: ["hover:bg-harmony-caution-50"],
  },
  strong: {
    positive: ["hover:bg-harmony-positive-800"],
    information: ["hover:bg-harmony-info-800"],
    critical: ["hover:bg-harmony-critical-800"],
    caution: ["hover:bg-harmony-caution-300"],
  },
}

const alertToneIconClasses = {
  default: {
    positive: ["fill-harmony-positive-700"],
    information: ["fill-harmony-info-700"],
    critical: ["fill-harmony-critical-700"],
    caution: ["fill-harmony-caution-700"],
  },
  strong: {
    positive: ["fill-harmony-positive-50"],
    information: ["fill-harmony-info-50"],
    critical: ["fill-harmony-critical-50"],
    caution: ["fill-harmony-caution-900"],
  },
}

/**
 * Alert component for displaying messages with different tones.
 *
 * @param {Object} props - The props for the Alert component.
 * @param {"positive" | "information" | "critical" | "caution"} props.tone - The tone of the alert, which determines its styling.
 * @param {"default" | "strong"} props.variant - The variant of the alert, which determines its styling.
 * @param {React.ReactNode} props.children - The content of the alert, typically text or other elements.
 * @param {Function} [props.onClose] - Optional callback function to handle the close action. When provided, a close button will be rendered.
 * @param {boolean} [props.isFullWidth=true] - Whether the button is full width.
 * @param {string} [props.className] - Additional custom classes to apply to the alert.
 */

export function Alert({
  tone = "positive",
  variant = "default",
  children,
  onClose = null,
  isFullWidth = true,
  className = "",
}) {
  const alertClasses = [
    "flex",
    "py-4",
    "px-4",
    "gap-4",
    "items-stretch",
    "rounded-xl",
    ...alertToneClasses[variant][tone],
  ]

  if (className) {
    alertClasses.push(className)
  }

  if (!isFullWidth) {
    alertClasses.push("max-w-[360px]")
  }

  const iconName = toneToIcon[tone]
  const isDismissable = Boolean(onClose)

  const buttonClasses = ["flex", "items-center", "justify-center", "ml-auto"]

  return (
    <div
      role="alert"
      aria-live={onClose ? "polite" : "assertive"}
      className={alertClasses.join(" ")}
    >
      <div className="min-w-[24px]">
        <Icon
          name={iconName}
          className={alertToneButtonClasses[variant][tone].join(" ")}
        />
      </div>
      <div className="text-base font-regular flex-grow">{children}</div>
      {isDismissable && (
        <div className={buttonClasses.join(" ")}>
          <ButtonIcon
            icon="X"
            onClick={onClose}
            variant="transparent"
            size="small"
            ariaLabel="Close alert"
            className={alertToneButtonClasses[variant][tone].join(" ")}
            iconProps={{
              className: alertToneIconClasses[variant][tone].join(" "),
            }}
          />
        </div>
      )}
    </div>
  )
}

Alert.propTypes = {
  tone: PropTypes.oneOf(["positive", "information", "critical", "caution"])
    .isRequired,
  variant: PropTypes.oneOf(["default", "strong"]),
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  isFullWidth: PropTypes.bool,
  className: PropTypes.string,
}
