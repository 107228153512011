import { useState, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { usePopper } from "react-popper"
import { createPortal } from "react-dom"

/**
 * Popper component for positioning elements relative to a reference element.
 *
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - The content of the popper.
 * @param {React.RefObject} props.referenceElement - The reference element to position the popper against.
 * @param {string} [props.placement='bottom'] - The placement of the popper relative to the reference element.
 * @param {Array} [props.modifiers=[]] - Popper.js modifiers to customize behavior. https://popper.js.org/docs/v2/modifiers/
 */

export function Popper({
  children,
  referenceElement,
  placement = "bottom",
  modifiers = [],
}) {
  const [popperElement, setPopperElement] = useState(null)
  const [container] = useState(() => document.createElement("div"))

  useLayoutEffect(() => {
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  }, [container])

  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement,
    {
      placement,
      modifiers,
    },
  )

  const popperContent = (
    <div
      ref={setPopperElement}
      style={{ ...styles.popper, zIndex: 40 }}
      {...attributes.popper}
    >
      {children}
    </div>
  )

  return createPortal(popperContent, container)
}

Popper.propTypes = {
  children: PropTypes.node.isRequired,
  referenceElement: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  placement: PropTypes.oneOf([
    "auto",
    "auto-start",
    "auto-end",
    "top",
    "top-start",
    "top-end",
    "right",
    "right-start",
    "right-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end",
  ]),
  modifiers: PropTypes.arrayOf(PropTypes.object),
}
