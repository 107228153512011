import { useState, useEffect } from "react"
import {
  loadIframe,
  closedGoogleModal,
  applePayNotSupportedOnBrowser,
  firstCharToLower,
  getSessionKeyByUrl,
  check3ds,
  extractIframeAttributes,
} from "./utils"
import { STATUS_CODES } from "./constants"

export const useFreedomPay = ({
  config,
  getIframeUrls,
  onError,
  onConfirmation,
}) => {
  const [iframeUrls, setIframeUrls] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const initialize = async () => {
      try {
        if (!config.sdkUrl) {
          throw new Error("missing sdk url config")
        }
        await loadIframe(config.sdkUrl)
        const urls = await getIframeUrls()
        setIframeUrls(urls)
      } catch (err) {
        handleOnError(err)
      } finally {
        setIsLoading(false)
      }
    }

    initialize()
  }, [])

  useEffect(() => {
    window.addEventListener("message", handleIframeMessage)

    return () => {
      window.removeEventListener("message", handleIframeMessage)
    }
  }, [iframeUrls])

  const handleOnError = (error) => {
    window.removeEventListener("message", handleIframeMessage)
    onError(error)
  }

  const handleIframeMessage = async (event) => {
    console.log(event, "TEMPORARY HANDLE FREEDOMPAY EVENT \n\n")

    if (event.data.type === STATUS_CODES.FAILED) {
      const closedModal = closedGoogleModal(event.data)
      if (closedModal) return

      const applePlayNotSupportedBrowser = applePayNotSupportedOnBrowser(event)
      if (!applePlayNotSupportedBrowser) {
        handleOnError(event.data)
        return
      }

      // eslint-disable-next-line no-unused-vars
      const { applePay, ...restUrls } = iframeUrls
      setIframeUrls(restUrls)
    }

    if (event.data.type === STATUS_CODES.CHECKING_3DS) {
      if (!check3ds(event.data)) {
        onError(event.data)
        return
      }
    }

    if (event.data.type === STATUS_CODES.PAYMENT_CHECK) {
      const paymentType = firstCharToLower(event?.data?.data?.paymentType)
      const sessionKey = getSessionKeyByUrl(paymentType, iframeUrls)
      const paymentId = event.data?.data?.paymentKeys[0]

      await onConfirmation({
        paymentType,
        sessionKey,
        paymentId,
      })
    }
  }

  const renderIframe = (type) => {
    const html = iframeUrls?.[type]
    if (!html) return null

    const attrs = extractIframeAttributes(html)
    if (!attrs) return null

    const iframeStyle =
      type === "card"
        ? { height: "420px", width: "100%" }
        : { height: "40px", width: "100%", borderRadius: "99px" }

    return (
      <iframe
        id={attrs.id}
        src={attrs.src}
        aria-label={attrs.ariaLabel}
        referrerPolicy="strict-origin-when-cross-origin"
        style={iframeStyle}
      />
    )
  }

  return { iframeUrls, isLoading, renderIframe }
}
