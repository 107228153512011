import { useState, useEffect } from "react"
import { usePaymentService } from "modules/checkout/hooks"

export function useGetPaymentProviderConfigs({ reservationId }) {
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState(null)
  const paymentService = usePaymentService()

  useEffect(() => {
    const getConfigs = async () => {
      try {
        setIsLoading(true)
        const response =
          await paymentService.getPaymentProviderConfig(reservationId)
        setConfig(response.config)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    getConfigs()
  }, [])

  return { isLoading, config }
}
