import { Error } from "./Error"

export function BillNotFound({ reservationId, guestEmail }) {
  return (
    <Error heading="Bill not found">
      <p>
        It looks like we couldn&apos;t find any bill data for this reservation.
      </p>
      <p>
        Please close your browser and visit the link you were sent in the bill
        welcome message again.
      </p>
      <p>
        If this problem persists, please speak to your wait staff who can check
        the details for you.
      </p>
      <p>
        Reservation ID: <span className="font-semibold">{reservationId}</span>
        <br />
        Reservation Email:{" "}
        <span className="font-semibold">
          {guestEmail ? guestEmail : "Guest email not found"}
        </span>
      </p>
    </Error>
  )
}
