import { forwardRef } from "react"
import { Message } from "./Message"

/**
 * Field component that wraps an input element with a label and an optional helper message.
 *
 * @param {string} [label] - The text for the field label.
 * @param {string} [helperText] - Additional text for guidance or validation.
 * @param {"default" | "positive" | "critical" | "caution"} [helperType="default"] - Type of helper message.
 * @param {React.ReactNode} children - The input or form element wrapped inside the field.
 * @param {React.Ref} ref - Forwarded ref for the field container.
 */

const Field = forwardRef(
  (
    {
      fieldId,
      label,
      helperText,
      helperType = "default",
      children,
      fieldProps = {
        labelClassName: "",
      },
      component: Wrapper = "label",
    },
    ref,
  ) => {
    const labelClassNames = [
      "block",
      "text-base",
      "text-harmony-text-base",
      "dark:text-harmony-text-base-dark",
      "mb-2",
    ]

    if (fieldProps.labelClassName) {
      labelClassNames.push(fieldProps.labelClassName)
    }

    const Label = Wrapper === "fieldset" ? "legend" : "span"

    return (
      <div ref={ref}>
        <Wrapper className="block" htmlFor={fieldId}>
          <Label className={labelClassNames.join(" ")}>{label}</Label>
          <div>{children}</div>
        </Wrapper>
        <Message
          fieldId={fieldId}
          helperText={helperText}
          helperType={helperType}
        />
      </div>
    )
  },
)

Field.displayName = "Field"

export { Field }
