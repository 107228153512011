export const ReduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION__?.connect({
  name: "CheckoutReducer",
})

export const PAYMENT_STATE = {
  Cancelled: "CANCELLED",
  Failed: "FAILED",
  Processing: "PROCESSING",
  Successful: "SUCCESSFUL",
}
