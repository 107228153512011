export function Typography({
  children,
  // size = "base",
  // weight = "regular",
  className = "",
}) {
  const typographyClasses = [
    "text-base",
    "font-regular",
    "text-harmony-text-base",
    "dark:text-harmony-text-base-dark",
  ]

  if (className) {
    typographyClasses.push(className)
  }

  return <p className={typographyClasses.join(" ")}>{children}</p>
}
