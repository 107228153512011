import React from "react"
import { useTenantConfig } from "@hooks"
import { Panel, Heading } from "@harmony"

function TermsAndConditions() {
  const { name } = useTenantConfig()
  return (
    <Panel>
      <div className="mx-6 min-h-screen">
        <Heading level={1}>Terms &amp; Conditions</Heading>
        <section className="my-6 space-y-4">
          <Heading level={2}>Cancellation Policy</Heading>
          <p>
            The following represents an agreement between {name} (the
            &ldquo;Restaurant&rdquo;) and you and outlines specific conditions
            relating to your booking (the &ldquo;Agreement&rdquo;).
          </p>
          <p>
            In consideration for the restaurant making a reservation on your
            behalf, you are required to provide us with credit or debit card
            details to secure all parties. No money will be charged onto your
            card other than in accordance with the cancellation policy below.
          </p>
          <p>
            If the restaurant is unable to perform its obligations under this
            agreement for any reason beyond the restaurant&rsquo;s reasonable
            control, including but not limited to: fire; flood or other
            emergency conditions; or the carrying out of necessary and essential
            repairs to the restaurant (or any delay to the completion of such
            repairs), the restaurant shall have no liability to you of any kind
            in respect of such non-performance.
          </p>
          <p>
            Cancellations (including partial cancellations) must be actioned
            using the link in your confirmation email, 24 hours&rsquo; prior to
            your reservation date.
          </p>
          <p>
            In the event that the reservation is not honoured in whole by you or
            is cancelled with less than the required notice period, the
            restaurant may, at its discretion, charge &pound;X per person to the
            credit or debit card that you have provided to secure your
            reservation.
          </p>
          <p>
            Please note that the above will vary for special events and private
            dining. Please speak to a member of our team for full details.
          </p>
        </section>
      </div>
    </Panel>
  )
}

export default TermsAndConditions
