import { Bleed, ButtonIcon, Spread } from "@harmony"

export function ModalControls({ onNavigateBack, onClose }) {
  return (
    <div className="pt-2 mb-2">
      <Bleed bleedX="2">
        <Spread>
          {onNavigateBack && (
            <ButtonIcon
              icon="ArrowLeft"
              variant="transparent"
              onClick={onNavigateBack}
            />
          )}

          <ButtonIcon
            icon="X"
            variant="transparent"
            onClick={onClose}
            className="ml-auto"
          />
        </Spread>
      </Bleed>
    </div>
  )
}
