import { useCheckout } from "modules/checkout/hooks"
import { PAYMENT_STATE } from "modules/checkout/reducers/helpers"
import { toast } from "react-toastify"
import { usePaymentService, useReservation } from "modules/checkout/hooks"
import useSchema from "hooks/useSchema"
import { handle3dsSoftDecline } from "./utils"

export const useSharedFreedomPay = () => {
  const { state, setPaymentState, onPayment, getPayment } = useCheckout()
  const paymentService = usePaymentService()
  const { schema } = useSchema()
  const { reservation } = useReservation()

  const onPaymentError = (error) => {
    setPaymentState(PAYMENT_STATE.Failed)
    toast.error("Failed to process your payment")
    console.log(error, "error")
  }

  const confirmIntent = async ({
    paymentId,
    sessionKey,
    paymentType = null,
  }) => {
    setPaymentState(PAYMENT_STATE.Processing)
    try {
      const { paymentRecord } = await paymentService.confirmPaymentIntent({
        reservationId: reservation.reservationId,
        paymentId,
        sessionKey,
        state,
        paymentType,
      })

      onPayment({
        ...paymentRecord,
        tip: {
          tipType: state.tipType,
          tipAmount: state.tipAmount,
        },
      })
      await getPayment()

      setPaymentState(PAYMENT_STATE.Successful)
    } catch (e) {
      const is3dsSoftDecline = await handle3dsSoftDecline(e)
      if (is3dsSoftDecline) {
        setPaymentState(null)
        return
      }

      setPaymentState(PAYMENT_STATE.Failed)
    }
  }
  const createIntent = async (iframeTypes = []) => {
    const { paymentIntent } = await paymentService.createPaymentIntent(
      reservation.reservationId,
      state,
      state.share,
      {
        email: state.user.emailAddress,
        name: state.user.fullName,
      },
      schema,
      iframeTypes,
    )

    return paymentIntent
  }
  return { onPaymentError, createIntent, confirmIntent }
}
