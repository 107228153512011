import { useState } from "react"
import { Outlet, useParams } from "react-router-dom"

import SideMenu from "../sideMenu"
import FrequentlyAskedQuestions from "../sideMenu/FrequentlyAskedQuestions"
import TermsAndConditions from "../sideMenu/TermsAndConditions"

import {
  CheckoutProvider,
  InvoiceProvider,
  LoadingProvider,
  ReservationProvider,
  RestaurantProvider,
} from "./context"

import { ButtonIcon, Logo, Panel } from "@harmony"

export function Template() {
  const { reservationId } = useParams()

  const [toggleMenu, setToggleMenu] = useState(false)
  const [menuIndex, setMenuIndex] = useState(1)

  return (
    <LoadingProvider>
      <ReservationProvider reservationId={reservationId}>
        <RestaurantProvider>
          <InvoiceProvider>
            <CheckoutProvider>
              <div className="text-harmony-text-base dark:text-harmony-text-base-dark  h-full">
                <div className="relative w-full lg:max-w-[50%] mx-auto min-h-screen flex flex-col bg-harmony-surface-level-1 dark:bg-harmony-surface-level-1-dark">
                  {toggleMenu && (
                    <SideMenu
                      toggleMenu={toggleMenu}
                      toggleMenuHandler={() => setToggleMenu(false)}
                      activeIndex={menuIndex}
                      setMenuIndex={setMenuIndex}
                    />
                  )}

                  <Panel>
                    <div className="w-full bg-harmony-surface-base dark:bg-harmony-surface-base-dark flex justify-between items-center px-6 md:pt-8">
                      <Logo variant="icon" />
                      <ButtonIcon
                        icon="List"
                        onClick={() => setToggleMenu(!toggleMenu)}
                      />
                    </div>
                  </Panel>

                  {menuIndex === 1 && (
                    <div className="flex flex-1 text-harmony-text-base dark:text-harmony-text-base-dark">
                      <Outlet />
                    </div>
                  )}

                  {menuIndex === 2 && <FrequentlyAskedQuestions />}
                  {menuIndex === 3 && <TermsAndConditions />}
                </div>
              </div>
            </CheckoutProvider>
          </InvoiceProvider>
        </RestaurantProvider>
      </ReservationProvider>
    </LoadingProvider>
  )
}
