import { CODES_ALLOWED_3DS, FREEDOMPAY_3DS_SOFT_DECLINE } from "./constants"

export const loadIframe = (sdkUrl) => {
  return new Promise((resolve, reject) => {
    if (document.querySelector('script[src*="freedompay"]')) {
      resolve()
      return
    }

    const script = document.createElement("script")
    script.src = sdkUrl
    script.async = true
    script.onload = () => resolve()
    script.onerror = () => reject(new Error("Failed to load FreedomPay script"))
    document.head.appendChild(script)
  })
}

export const applePayNotSupportedOnBrowser = (event) => {
  return event?.data?.data?.errors?.find(
    (e) =>
      e?.emittedBy === "ApplePay" &&
      e?.messageCode === "applePayBrowserNotSupported",
  )
}

export const closedGoogleModal = ({ data }) => {
  const error = data?.errors?.[0]

  if (!error) return

  return (
    error.emittedBy === "RequestPaymentKey" &&
    error.errorType === 0 &&
    error.messageCode === "unknownError"
  )
}

export const check3ds = (event) => {
  const isFrictionless = event?.data?.CmpiLookup?.EciFlag
  const isStepUp = event?.data?.Payment?.ExtendedData?.ECIFlag
  const eciValue = isFrictionless || isStepUp

  return eciValue && CODES_ALLOWED_3DS.includes(eciValue)
}

export const firstCharToLower = (str) => {
  return str[0].toLowerCase() + str.slice(1)
}

export const getSessionKeyByUrl = (paymentType, iframeUrls) => {
  const type = iframeUrls[paymentType]
  const url = new URL(type.match(/src="([^"]*)/)[1])
  const sessionKey = url.searchParams.get("sessionKey")
  return sessionKey
}

export const extractIframeAttributes = (html) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")
  const iframe = doc.querySelector("iframe")

  if (!iframe) return null

  return {
    id: iframe.id,
    src: iframe.src,
    ariaLabel: iframe.getAttribute("aria-label"),
  }
}

export const handle3dsSoftDecline = async (error) => {
  if (error.message !== FREEDOMPAY_3DS_SOFT_DECLINE) return

  await window.FreedomPay.Apm.ConsumerAuth.invoke({
    mandateChallenge: true,
  })

  return true
}
