import { TipType } from "../api"
import {
  LOAD_STATE,
  LOAD_USER,
  ON_PAYMENT,
  PAY_FULL,
  PAY_SPLIT,
  SET_TIP,
  SET_PAYMENT_STATE,
  SET_USER_DATA_CARD_PAYMENT,
} from "./actions"

import { ReduxDevtools, PAYMENT_STATE } from "./helpers"

export const CHECKOUT_INITIAL_STATE = {
  splitBy: 1,
  paymentState: null,
  splitSize: 1,
  tipType: TipType.Fixed,
  tipAmount: "",
  forceSplitBy: false,
  isPayingFull: false,
  isPayingSplit: false,
  hasSettledBill: false,
  hasPartiallyPaid: false,
  remainingSplits: 1,
  currency: "gbp",
  share: {
    subtotal: "",
    tax: "",
    service: "",
    total: "",
    tip: "",
    preTipTotal: "",
  },
  shareRaw: {
    subtotal: 0,
    tax: 0,
    service: 0,
    total: 0,
    tip: 0,
    preTipTotal: 0,
  },
  total: {
    subtotal: "",
    tax: "",
    service: "",
    total: "",
  },
  collected: {
    subtotal: "",
    tax: "",
    service: "",
    total: "",
    tip: "",
  },
  user: {
    guestId: crypto.randomUUID(),
    emailAddress: "guest@zumi.app",
    fullName: "Zumi Guest",
    isOwner: false,
  },
  userDataCardPayment: {
    email: "",
    billingAddress: "",
    city: "",
    countryCode: "GBR",
    firstName: "",
    lastName: "",
    postalCode: "",
    phone: "+44",
  },
  payments: [],
}

export function CheckoutReducer(state, { type, payload }) {
  const newState = ((state, { type, payload }) => {
    switch (type) {
      case SET_TIP:
      case PAY_FULL:
      case PAY_SPLIT: {
        return {
          ...state,
          ...payload,
        }
      }
      case SET_USER_DATA_CARD_PAYMENT: {
        return {
          ...state,
          userDataCardPayment: {
            ...state.userDataCardPayment,
            ...payload,
          },
        }
      }

      case LOAD_STATE: {
        const forceSplitBy = payload.splitBy === payload.remainingSplits

        const isPayingFull = payload.splitBy === 1
        const isPayingSplit = payload.splitBy > 1

        const hasSettledBill = payload.paid
        const hasPartiallyPaid =
          !hasSettledBill && payload.remainingSplits < payload.splitBy

        return {
          ...state,
          splitBy: payload.splitBy,
          share: payload.share,
          shareRaw: payload.shareRaw,
          total: payload.total,
          collected: payload.collected,
          remainingSplits: payload.remainingSplits,
          currency: payload.currency,
          forceSplitBy,
          isPayingFull,
          isPayingSplit,
          paymentState: hasSettledBill ? PAYMENT_STATE.Successful : null,
          hasSettledBill,
          hasPartiallyPaid,
        }
      }

      case LOAD_USER: {
        return {
          ...state,
          user: {
            guestId: payload.guestId,
            emailAddress: payload.emailAddress,
            fullName: payload.fullName,
            isOwner: payload.isOwner,
          },
        }
      }

      case ON_PAYMENT: {
        return {
          ...state,
          payments: [...state.payments, payload],
        }
      }

      case SET_PAYMENT_STATE: {
        return {
          ...state,
          paymentState: payload,
        }
      }

      default:
        return state
    }
  })(state, { type, payload })

  ReduxDevtools?.send({ type, payload }, newState)

  return newState
}
