export const getSelectedValueIndex = (items = [], selectedValue = null) => {
  if (!selectedValue) {
    return -1
  }

  return items.findIndex((item) => item.value === selectedValue)
}

export const getValue = (defaultValue, selectedValue, placeholder) => {
  if (selectedValue) {
    return selectedValue
  }

  if (defaultValue) {
    return defaultValue
  }

  return placeholder
}

export const getLabel = (items, value, placeholder) => {
  if (value) {
    return items.find((item) => item.value === value)?.label ?? placeholder
  }

  return placeholder
}
