import PropTypes from "prop-types"

export const headingLevelClasses = {
  1: ["text-3xl"],
  2: ["text-2xl"],
  3: ["text-xl"],
  4: ["text-base"],
}

export const headingWeightClasses = {
  regular: [
    "font-semibold",
    "text-harmony-text-base",
    "dark:text-harmony-text-base-dark",
  ],
  weak: [
    "font-regular",
    "text-harmony-text-subtle",
    "dark:text-harmony-text-subtle-dark",
  ],
}

/**
 * Heading component for rendering semantic HTML heading elements with customizable level and weight.
 *
 * @param {Object} props - The props for the Heading component.
 * @param {1 | 2 | 3 | 4} props.level - The heading level, determines the HTML tag (e.g., 1 for <h1>, 2 for <h2>, etc.).
 * @param {"regular" | "weak"} props.weight - The visual weight of the heading, indicating its emphasis or importance.
 * @param {React.ReactNode} props.children - The content to be displayed inside the heading.
 * @param {string} [props.className] - Additional CSS classes for further customisation.
 */

export function Heading({
  level = 1,
  weight = "regular",
  children,
  className = "",
}) {
  level = Math.min(4, Math.max(1, level))

  const headingClasses = [
    ...headingLevelClasses[level],
    ...headingWeightClasses[weight],
  ]

  if (className) {
    headingClasses.push(className)
  }

  const HeadingTag = `h${level}`

  return (
    <HeadingTag className={headingClasses.join(" ")}>{children}</HeadingTag>
  )
}

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4]),
  weight: PropTypes.oneOf(["regular", "weak"]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
