import { useEffect, useState } from "react"

import {
  getCookieConsent,
  setCookieConsent,
  setPosthogPersistence,
} from "../helpers"

/**
 * Custom hook to manage cookie consent state and persistence
 *
 * @returns {Object} Cookie consent state and handlers
 * @property {string} consentGiven - Current consent state ("yes", "no", "undecided", or undefined if not loaded)
 * @property {Function} acceptCookies - Handler to accept cookies and persist choice
 * @property {Function} rejectCookies - Handler to reject cookies and persist choice
 */

export const useCookieConsent = () => {
  const [consentGiven, setConsentGiven] = useState()

  useEffect(() => {
    setConsentGiven(getCookieConsent())
  }, [])

  useEffect(() => {
    if (consentGiven === "yes") {
      setPosthogPersistence(consentGiven)
    }
  }, [consentGiven])

  const setConsent = (consent) => {
    setCookieConsent(consent)
    setConsentGiven(consent)

    return consent
  }

  const acceptCookies = () => setConsent("yes")
  const rejectCookies = () => setConsent("no")

  return { consentGiven, acceptCookies, rejectCookies }
}
