import { useNavigate, useSearchParams } from "react-router-dom"
import { CHECKOUT_ROUTE, getRoutePath } from ".."
import { useReservation } from "./useReservation"

export function useNavigateExtended() {
  const { reservation } = useReservation()
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const append = (newRoute, state = {}) => {
    const path = getRoutePath(newRoute)

    if (!path) {
      return
    }

    const route = `${CHECKOUT_ROUTE}/${reservation.reservationId}/${path}?${searchParams}`
    navigate(route, { state })
  }

  const reset = () => {
    const route = `${CHECKOUT_ROUTE}/${reservation.reservationId}/?${searchParams}`
    navigate(route)
  }

  const previous = () => {
    navigate(-1)
  }

  return { append, reset, previous }
}
