import { useCheckout } from "../../hooks"
import { PassBill } from "../PassBill/PassBill"

export function ShareBill() {
  const { onEndFlow } = useCheckout()

  return (
    <PassBill
      title="Share the rest of the bill"
      description="Use the options below to share the rest of the bill with your guests."
      onNavigateBack={onEndFlow}
    />
  )
}
