import { ModalControls, PriceDetails } from "modules/checkout/parts"
import { useNavigateExtended, useCheckout } from "modules/checkout/hooks"
import { Stack, Heading, Text } from "@harmony"
import { FreedomPay } from "./FreedomPay"
import { PaymentStatus } from "modules/checkout/parts"
import { PAYMENT_PROVIDERS } from "modules/checkout/constants"

const getPaymentProvider = (config) => {
  const provider = config.provider

  switch (provider) {
    case PAYMENT_PROVIDERS.FREEDOMPAY:
      return <FreedomPay config={config} />

    default:
      return null
  }
}

export const PaymentCardDetailsBill = () => {
  const navigate = useNavigateExtended()
  const { onEndFlow, state, config } = useCheckout()

  if (state.paymentState) {
    return <PaymentStatus paymentState={state.paymentState} />
  }

  return (
    <>
      <ModalControls
        onNavigateBack={() => {
          navigate.previous()
        }}
        onClose={onEndFlow}
      />
      <Stack>
        <Heading level={2}>Enter card details</Heading>
        <Text>Enter your card details to finalise your payment. </Text>
        <PriceDetails {...state.share} />
      </Stack>
      {getPaymentProvider(config)}
    </>
  )
}
