import { Outlet, Route } from "react-router"
import { Modal } from "@harmony"
import { useCheckout } from "modules/checkout/hooks"
import {
  Paths,
  Routes,
  CHECKOUT_ROUTE,
  PAYMENT_PROVIDERS,
} from "modules/checkout/constants"
import {
  AddTip,
  LiveBill,
  PassBill,
  PayBill,
  SettleBill,
  ShareBill,
  SplitBill,
  PaymentCardUserDetailsBill,
  PaymentCardDetailsBill,
} from "."
import { Template } from "../Template"

const PathToRoute = Object.fromEntries(
  Object.entries(Paths).map(([key, value]) => [value, key]),
)

function getLastPathSegment(path) {
  const cleanPath = path.split("?")[0]
  const segments = cleanPath.split("/").filter(Boolean)
  return segments.length > 0 ? segments[segments.length - 1] : null
}

export function getRouteFromPath(path) {
  const segment = getLastPathSegment(path)
  return PathToRoute[segment] || "INDEX_ROUTE"
}

export function getRoutePath(route) {
  return Paths[route]
}

function FreedomPayRoutesWrapper() {
  const { config } = useCheckout()

  if (config?.provider !== PAYMENT_PROVIDERS.FREEDOMPAY) return null

  return <Outlet />
}

export function CheckoutRouting() {
  return (
    <Route path={`${CHECKOUT_ROUTE}/:reservationId`} element={<Template />}>
      <Route element={<LiveBill />}>
        <Route index />
        <Route
          element={
            <Modal>
              <Outlet />
            </Modal>
          }
        >
          <Route path={Paths[Routes.AddTip]} element={<AddTip />} />
          <Route path={Paths[Routes.PassBill]} element={<PassBill />} />
          <Route path={Paths[Routes.PayBill]} element={<PayBill />} />
          <Route path={Paths[Routes.SettleBill]} element={<SettleBill />} />
          <Route path={Paths[Routes.ShareBill]} element={<ShareBill />} />
          <Route path={Paths[Routes.SplitBill]} element={<SplitBill />} />
          <Route element={<FreedomPayRoutesWrapper />}>
            <Route
              path={Paths[Routes.PaymentCardUserDetailsBill]}
              element={<PaymentCardUserDetailsBill />}
            />
            <Route
              path={Paths[Routes.PaymentCardDetailsBill]}
              element={<PaymentCardDetailsBill />}
            />
          </Route>
        </Route>
      </Route>
    </Route>
  )
}
