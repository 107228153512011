import { Heading, Icon, Stack, Text } from "@harmony"
import { Routes } from "../.."
import {
  EVENT_BILL_INTENTION,
  useCheckout,
  useEventTracker,
  useNavigateExtended,
} from "../../hooks"
import { ModalControls } from "../../parts"

function ChonkyButton({ onClick, icon, children }) {
  return (
    <button
      onClick={onClick}
      className="flex flex-col bg-harmony-surface-level-1 dark:bg-harmony-surface-level-1-dark rounded-2xl items-center justify-center w-full p-4 gap-2"
    >
      <Icon name={icon} />
      {children}
    </button>
  )
}

export function SettleBill() {
  const { setPayFull, onEndFlow } = useCheckout()
  const navigate = useNavigateExtended()
  const { onEvent } = useEventTracker()

  function onPayBillFull() {
    setPayFull()
    onEvent(EVENT_BILL_INTENTION, { type: "Full" })
    navigate.append(Routes.AddTip)
  }

  function onPayBillSplit() {
    onEvent(EVENT_BILL_INTENTION, { type: "Split" })
    navigate.append(Routes.SplitBill)
  }

  function onPassBill() {
    onEvent(EVENT_BILL_INTENTION, { type: "Pass" })
    navigate.append(Routes.PassBill)
  }

  return (
    <>
      <ModalControls onClose={onEndFlow} />
      <Stack>
        <Heading level={2} className="mb-4">
          Settle the bill
        </Heading>
        <Text className="mb-6">
          Choose how you&apos;d like to pay the bill with our checkout partner
          Zumi
        </Text>

        <Stack>
          <ChonkyButton onClick={onPayBillFull} icon="CreditCard">
            <Text weight="strong">Pay in full</Text>
          </ChonkyButton>

          <div className="flex flex-row gap-2">
            <ChonkyButton onClick={onPayBillSplit} icon="UsersThree">
              <Text weight="strong">Split the bill</Text>
            </ChonkyButton>
            <ChonkyButton onClick={onPassBill} icon="ShareFat">
              <Text weight="strong">Pass the bill</Text>
            </ChonkyButton>
          </div>
        </Stack>
      </Stack>
    </>
  )
}
