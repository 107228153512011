import { useAccordionContext } from "@harmony"
import { useCollapse } from "react-collapsed"

/**
 * Renders a collapsible body section for an accordion using `react-collapsed`.
 *
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The nested content displayed inside the body.
 */

export function Body({ children, ...props }) {
  const { headerId, bodyId, isOpen } = useAccordionContext()

  const { getCollapseProps } = useCollapse({
    isExpanded: isOpen,
    duration: 300,
    easing: "ease-in-out",
  })

  return (
    <div
      {...getCollapseProps()}
      role="region"
      id={bodyId}
      aria-labelledby={headerId}
      aria-hidden="false"
      {...props}
    >
      {children}
    </div>
  )
}
