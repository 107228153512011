/**
 * A floating panel component that slides up from the bottom of the screen.
 *
 * @component
 * @param {Object} props - The component props
 * @param {boolean} props.open - Controls whether the panel is visible or hidden
 * @param {React.ReactNode} props.children - The content to be displayed inside the panel
 * @param {string} props.ariaLabel - Accessibility label for the dialog
 * @returns {JSX.Element} The SlideUpPanel component
 */

const SlideUpPanel = ({ open, children, ariaLabel }) => {
  return (
    <div
      role="dialog"
      aria-label={ariaLabel}
      className={`fixed bottom-0 inset-x-0 transform ${
        open ? "translate-y-0" : "translate-y-full"
      } z-50 max-h-[90%] overflow-y-auto py-4 px-6 transition-transform duration-300 ease-in-out lg:mx-auto lg:max-w-[50%]`}
    >
      <div className="shadow-lg-no-spread rounded-3xl bg-harmony-surface-level-1 dark:bg-harmony-surface-level-1-dark shadow-harmony-brand-accent-700/25 dark:shadow-harmony-brand-accent-950/30">
        <div className="p-6 md:p-8">{children}</div>
      </div>
    </div>
  )
}

export default SlideUpPanel
