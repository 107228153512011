import React from "react"
import ReactDOM from "react-dom/client"
import "react-toastify/dist/ReactToastify.css"

import CookieNotice from "./components/custom/CookieNotice"
import { PostHogProvider } from "./providers/posthog"

import { App } from "./App"

import "./index.css"

const container = document.getElementById("root")
const root = ReactDOM.createRoot(container)

root.render(
  <PostHogProvider>
    <App />
    <CookieNotice />
  </PostHogProvider>,
)
