import ModalWrapper from "react-modal"

if (typeof document !== "undefined" && process.env.NODE_ENV !== "test") {
  const rootElement = document.getElementById("root") || document.body
  ModalWrapper.setAppElement(rootElement)
}

export function Modal({ children }) {
  return (
    <ModalWrapper
      isOpen={true}
      overlayClassName="fixed top-0 left-0 right-0 bottom-0 p-4 overflow-auto z-30 bg-harmony-surface-modal-bg dark:bg-harmony-surface-modal-bg-dark"
      className="rounded-3xl pt-5 pb-8 px-6 bg-harmony-surface-base dark:bg-harmony-surface-base-dark lg:max-w-[50%] m-auto"
    >
      <div>{children}</div>
    </ModalWrapper>
  )
}
