import { PaymentService } from "../api/PaymentService"

const paymentService = new PaymentService()

export const LOAD_STATE = "LOAD_STATE"
export const SET_TIP = "SET_TIP"
export const PAY_FULL = "PAY_FULL"
export const PAY_SPLIT = "PAY_SPLIT"

export const LOAD_USER = "LOAD_USER"
export const SET_PAYMENT_STATE = "SET_PAYMENT_STATE"
export const SET_USER_DATA_CARD_PAYMENT = "SET_USER_DATA_CARD_PAYMENT"
export const ON_PAYMENT = "ON_PAYMENT"

export const getPayment =
  (dispatch) =>
  async ({ reservationId, ...state }) => {
    try {
      const data = await paymentService.get(reservationId, state)

      if (!data) {
        return
      }

      dispatch({ type: LOAD_STATE, payload: data })
    } catch (e) {
      console.error(e)
      throw e
    }
  }

export const setTip =
  (dispatch, getState) =>
  async ({ reservationId, ...state }) => {
    const previousState = getState()

    dispatch({
      type: SET_TIP,
      payload: {
        tipAmount: state.tipAmount,
        tipType: state.tipType,
      },
    })

    try {
      const data = await paymentService.get(reservationId, state)

      if (!data) {
        return
      }

      dispatch({
        type: LOAD_STATE,
        payload: data,
      })
    } catch (e) {
      console.error(e)

      dispatch({
        type: SET_TIP,
        payload: {
          tipAmount: previousState.tipAmount,
          tipType: previousState.tipType,
        },
      })
    }
  }

export const setPayFull =
  (dispatch, getState) =>
  async ({ reservationId, splitSize, splitBy, ...state }) => {
    const previousState = getState()

    dispatch({
      type: PAY_FULL,
      payload: {
        splitSize,
        splitBy,
      },
    })

    try {
      const data = await paymentService.get(reservationId, {
        ...state,
        splitSize,
        splitBy,
      })

      if (!data) {
        return
      }

      dispatch({
        type: LOAD_STATE,
        payload: data,
      })
    } catch (e) {
      console.error(e)

      dispatch({
        type: PAY_FULL,
        payload: {
          splitSize: previousState.splitSize,
          splitBy: previousState.splitBy,
        },
      })
    }
  }

export const setPaySplitBy =
  (dispatch, getState) =>
  async ({ reservationId, splitBy, ...state }) => {
    const previousState = getState()

    dispatch({
      type: PAY_SPLIT,
      payload: {
        splitBy,
      },
    })

    try {
      const data = await paymentService.get(reservationId, {
        ...state,
        splitBy,
      })

      if (!data) {
        return
      }

      dispatch({
        type: LOAD_STATE,
        payload: data,
      })
    } catch (e) {
      console.error(e)

      dispatch({
        type: PAY_FULL,
        payload: {
          splitBy: previousState.splitBy,
        },
      })
    }
  }

export const setPaySplitSize =
  (dispatch, getState) =>
  async ({ reservationId, splitSize, ...state }) => {
    const previousState = getState()

    dispatch({
      type: PAY_SPLIT,
      payload: {
        splitSize,
      },
    })

    try {
      const data = await paymentService.get(reservationId, {
        ...state,
        splitSize,
      })

      if (!data) {
        return
      }

      dispatch({
        type: LOAD_STATE,
        payload: data,
      })
    } catch (e) {
      console.error(e)

      dispatch({
        type: PAY_SPLIT,
        payload: {
          splitSize: previousState.splitSize,
        },
      })
    }
  }

export const loadUser =
  (dispatch) =>
  ({ guestId, emailAddress, fullName, isOwner }) => {
    dispatch({
      type: LOAD_USER,
      payload: {
        guestId,
        emailAddress,
        fullName,
        isOwner,
      },
    })
  }

export const onPayment =
  (dispatch) =>
  ({ ...payment }) => {
    dispatch({
      type: ON_PAYMENT,
      payload: {
        ...payment,
      },
    })
  }

export const setPaymentState = (dispatch) => (paymentState) => {
  dispatch({
    type: SET_PAYMENT_STATE,
    payload: paymentState,
  })
}

export const setUserDataCardPayment = (dispatch) => (userData) => {
  dispatch({
    type: SET_USER_DATA_CARD_PAYMENT,
    payload: userData,
  })
}
