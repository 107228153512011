import { AccordionContext } from "./AccordionContext"

/**
 * Provides accordion state to child components via context.
 *
 * @param {Object} props - The props for the AccordionProvider.
 * @param {React.ReactNode} props.children - The child elements that should gain access to the accordion context.
 * @param {Object} props.value - The value to be passed to the AccordionContext.
 * @param {string} props.value.headerId - Unique id for accessibility linking.
 * @param {string} props.value.bodyId - Unique id for accessibility linking.
 * @param {boolean} props.value.isOpen - Indicates whether the accordion is currently open.
 * @param {() => void} props.value.onToggle - A function that toggles the accordion's open/closed state.
 */

export function AccordionProvider({ children, value }) {
  return (
    <AccordionContext.Provider value={value}>
      {children}
    </AccordionContext.Provider>
  )
}
