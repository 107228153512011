import { usePostHog } from "posthog-js/react"
import { useCheckout } from "./useCheckout"
import { useCallback } from "react"
import { useLocation } from "react-router"
import { getRouteFromPath } from "../routes"

export const EVENT_PAGE_VISIT = "EVENT_PAGE_VISIT"

export const EVENT_BILL_INTENTION = "EVENT_BILL_INTENTION"

export const EVENT_PAYMENT = "EVENT_PAYMENT"

export const EVENT_SPLIT_SIZE = "EVENT_SPLIT_SIZE"
export const EVENT_SPLIT_BY = "EVENT_SPLIT_BY"
export const EVENT_SPLIT_SET = "EVENT_SPLIT_SET"

export const EVENT_TIP_SELECTION = "EVENT_TIP_SELECTION"

export const EVENT_SUBMIT_REPORT_PROBLEM = "EVENT_SUBMIT_REPORT_PROBLEM"
export const EVENT_SUBMIT_EMAIL_RECEIPT = "EVENT_SUBMIT_EMAIL_RECEIPT"

export function useEventTracker() {
  const posthog = usePostHog()
  const { state } = useCheckout()
  const location = useLocation()

  const route = getRouteFromPath(location.pathname)

  const onEvent = useCallback(
    (event, payload = {}) => {
      if (!posthog) {
        console.warn("PostHog instance is not available")
        return
      }

      const {
        isPayingSplit,
        isPayingFull,
        tipAmount,
        tipType,
        user,
        splitSize,
        splitBy,
        remainingSplits,
      } = state
      const { isOwner } = user

      switch (event) {
        case EVENT_PAGE_VISIT:
          return posthog.capture(event, { ...payload, isOwner, route })

        case EVENT_BILL_INTENTION:
          return posthog.capture(event, { ...payload, isOwner })

        case EVENT_PAYMENT:
          return posthog.capture(event, {
            ...payload,
            isPayingSplit,
            isPayingFull,
            tipAmount,
            tipType,
            isOwner,
            splitSize,
            splitBy,
            remainingSplits,
          })

        case EVENT_TIP_SELECTION:
          return posthog.capture(event, {
            ...payload,
            isPayingSplit,
            isPayingFull,
            isOwner,
          })

        case EVENT_SUBMIT_REPORT_PROBLEM:
          return posthog.capture(event, { ...payload, isOwner })

        case EVENT_SUBMIT_EMAIL_RECEIPT:
          return posthog.capture(event, { ...payload, isOwner })
      }
    },
    [posthog, state],
  )

  return { onEvent }
}
