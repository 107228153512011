import { Panel, PromotionalTile } from "@harmony"

export function Rewards({ rewards }) {
  return rewards?.map((reward) => (
    <Panel background="transparent" key={reward.name}>
      <PromotionalTile
        title={reward.heading}
        body={reward.body}
        imageUrl={reward.image}
        decoration={reward.image && "image"}
        linkText={reward.linkText}
        href={reward.href}
      />
    </Panel>
  ))
}
