import posthog from "posthog-js"

const HIDE_COOKIE_NOTICE = process.env.REACT_APP_HIDE_COOKIE_NOTICE === "true"
const POSTHOG_ENABLED = process.env.REACT_APP_POSTHOG_ENABLED === "true"

export const setPosthogPersistence = (cookieConsent) => {
  if (!POSTHOG_ENABLED || HIDE_COOKIE_NOTICE) {
    return
  }

  posthog.set_config({
    persistence: cookieConsent === "yes" ? "localStorage+cookie" : "memory",
  })
}
