import { Popper } from "@harmony"
import { useEffect, useRef, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Item } from "./Item"

export function Items({
  items = [],
  fieldId = "",
  isOpen = false,
  onClose = () => {},
  onChange = () => {},
  parentRef,
  selectedIndex = -1,
  highlightedIndex = -1,
}) {
  const ref = useRef()

  const classes = [
    "rounded-lg",
    "shadow-lg",
    "overflow-hidden",
    "z-50",
    "bg-components-surface-base",
    "bg-white",
  ]

  const [width, setWidth] = useState(0)
  const updateWidth = () => {
    if (parentRef.current) {
      const { width } = parentRef.current.getBoundingClientRect()
      setWidth(width)
    }
  }

  useEffect(() => {
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  return (
    <Popper
      onClose={onClose}
      referenceElement={parentRef}
      placement="bottom-start"
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={classes.join(" ")}
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            style={{ width, transformOrigin: "top left" }}
          >
            <div className="max-h-[200px] overflow-y-auto" ref={ref}>
              <ul
                id={`${fieldId}-list`}
                aria-labelledby={fieldId}
                role="listbox"
              >
                {items.map((item, index) => {
                  const isSelected = selectedIndex === index
                  const isHighlighted = highlightedIndex === index

                  return (
                    <Item
                      id={`${fieldId}-${index}`}
                      key={`${fieldId}-${index}`}
                      item={item}
                      isHighlighted={isHighlighted}
                      isSelected={isSelected}
                      onSelection={onChange}
                    />
                  )
                })}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Popper>
  )
}
