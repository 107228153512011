import { Icon } from "@harmony"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { ZumiIconLightBlue } from "../../components/custom/Icons"

const menuItems = [
  { index: 1, label: "Your bill", requiresReservation: true },
  { index: 2, label: "FAQs", requiresReservation: false },
  { index: 3, label: "Terms & conditions", requiresReservation: false },
]

const MenuOverlay = ({ toggleMenuHandler, children }) => (
  <section
    className="bg-harmony-surface-base dark:bg-harmony-surface-base-dark h-full absolute right-0 top-0 w-full z-10"
    onClick={() => toggleMenuHandler()}
  >
    {children}
  </section>
)

const MenuItem = ({ label, menuIndex, handleClick }) => (
  <li
    onClick={() => handleClick(menuIndex)}
    className="w-full flex items-center justify-between cursor-pointer py-3 z-20"
  >
    <p className="text-harmony-text-base dark:text-harmony-text-base-dark text-xl lg:text-2xl font-semibold flex items-center">
      {label}
    </p>
    <Icon name="CaretRight" />
  </li>
)

const NavigationPanel = ({ children }) => (
  <nav className="w-3/4 bg-harmony-surface-level-1 dark:bg-harmony-surface-level-1-dark absolute right-0 top-0 h-screen flex flex-col items-start pr-4 pl-6 pt-16 pb-9 shadow-2xl">
    {children}
  </nav>
)

const NavMenu = ({ children }) => (
  <menu className="flex-1 overflow-y-auto w-full">{children}</menu>
)

const MenuWordmark = () => (
  <div className="flex items-center gap-4 pt-4">
    <ZumiIconLightBlue />
    <p className="text-harmony-text-subtle dark:text-harmony-text-subtle-dark">
      Powered by Zumi
    </p>
  </div>
)

const SideMenu = ({ toggleMenu, toggleMenuHandler, setMenuIndex }) => {
  const { reservationId } = useParams()

  const handleClick = (index) => {
    toggleMenuHandler()
    setMenuIndex(index)
  }

  useEffect(() => {
    if (toggleMenu) {
      document.body.classList.add("!overflow-hidden")
    } else {
      document.body.classList.remove("!overflow-hidden")
    }

    // clean up the body class on component unmount
    return () => {
      document.body.classList.remove("!overflow-hidden")
    }
  }, [toggleMenu])

  return (
    <MenuOverlay toggleMenuHandler={toggleMenuHandler}>
      <NavigationPanel>
        <NavMenu>
          {menuItems.map((item) => {
            return !item.requiresReservation ||
              (item.requiresReservation && reservationId) ? (
              <MenuItem
                key={item.index}
                menuIndex={item.index}
                handleClick={handleClick}
                label={item.label}
              />
            ) : null
          })}
        </NavMenu>
        <MenuWordmark />
      </NavigationPanel>
    </MenuOverlay>
  )
}

export default SideMenu
