import { useAccordionContext } from "../hooks"

export const buildAccordionHeaderProps = () => {
  const { headerId, bodyId, isOpen, onToggle } = useAccordionContext()

  return {
    role: "button",
    onClick: onToggle,
    onKeyDown: onToggle,
    "aria-expanded": isOpen ? "true" : "false",
    "aria-controls": bodyId,
    tabIndex: "0",
    id: headerId,
  }
}
