import { Icon } from "@harmony"
import PropTypes from "prop-types"
import { iconSizes } from "../../Icon/iconSizes"
import { buttonVariantClasses } from "../buttonVariantClasses"

const buttonIconSizeClasses = {
  default: ["h-12", "w-12", "text-base"],
  small: ["h-9", "w-9", "text-sm"],
}

/**
 * ButtonIcon component for icon-based buttons.
 * @param {Object} props - Component props.
 * @param {string} props.icon - Name of the Phosphor Icon to display.
 * @param {Function} [props.onClick] - Callback for single-click events.
 * @param {"soft" | "transparent"} [props.variant="transparent"] - Visual style of the button.
 * @param {"default" | "small"} [props.size="default"] - Size of the button.
 * @param {"neutral" | "brand"} [props.tone="neutral"] - Tone of the button.
 * @param {boolean} [props.isDisabled=false] - Whether the button is disabled.
 * @param {string} [props.ariaLabel] - Accessible label for the button.
 */
export function ButtonIcon({
  icon,
  onClick,
  variant = "transparent",
  size = "default",
  tone = "neutral",
  isDisabled = false,
  ariaLabel = "",
  className = "",
  iconProps = {
    className: "",
  },
}) {
  const variantClasses = buttonVariantClasses[variant][tone]
  const sizeClasses = buttonIconSizeClasses[size]

  const buttonClasses = [
    "flex",
    "justify-center",
    "items-center",
    "rounded-full",
    "font-semibold",
    "disabled:cursor-not-allowed",
    ...variantClasses,
    ...sizeClasses,
  ]

  if (className) {
    buttonClasses.push(className)
  }

  const iconSize = iconSizes[size]

  return (
    <button
      className={buttonClasses.join(" ")}
      onClick={onClick}
      disabled={isDisabled}
      aria-label={ariaLabel || icon}
      aria-disabled={isDisabled}
    >
      <Icon name={icon} size={iconSize} {...iconProps} />
    </button>
  )
}

ButtonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["transparent", "soft"]),
  size: PropTypes.oneOf(["default", "small"]),
  tone: PropTypes.oneOf(["neutral", "brand"]),
  isDisabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
}
