import PropTypes from "prop-types"

/**
 * A stack is a component that allows you to apply consistent spacing between elements.
 * It is a flex container that can be used to align items.
 *
 * @param {Object} props - The component props
 * @param {"0" | "1" | "2" | "3" | "4" | "6" | "8" | "12"} [props.space=4] - The spacing between elements, aligns to tailwind spacing classes
 * @param {React.ReactNode} props.children - The content of the stack.
 * @param {string} [props.align] - Optional alignment of the stack items.
 */
export function Stack({ space = "4", align, children }) {
  const itemAlignmentClasses = {
    left: "items-start",
    center: "items-center",
    right: "items-end",
  }

  const textAlignmentClasses = {
    left: "text-left",
    center: "text-center",
    right: "text-right",
  }

  const gapClasses = {
    0: "gap-0",
    1: "gap-1",
    2: "gap-2",
    3: "gap-3",
    4: "gap-4",
    6: "gap-6",
    8: "gap-8",
    12: "gap-12",
  }

  const classes = [
    "w-full",
    "flex",
    "flex-col",
    gapClasses[space],
    align && align !== "left" ? itemAlignmentClasses[align] : "",
    align ? textAlignmentClasses[align] : "",
  ]
    .filter(Boolean)
    .join(" ")

  return <div className={classes}>{children}</div>
}

Stack.propTypes = {
  space: PropTypes.oneOf(["0", "1", "2", "3", "4", "6", "8", "12"]),
  align: PropTypes.oneOf(["left", "center", "right"]),
  children: PropTypes.node.isRequired,
}
