import { Field, InputBase } from "@harmony"
import { forwardRef, useId, useRef, useState } from "react"

/**
 * TextField component for handling text input with label, helper text, and validation states.
 *
 * @param {string} [id] - The default value of the input field.
 * @param {string} [defaultValue] - The default value of the input field.
 * @param {string} [helperText] - Additional text for guidance or validation.
 * @param {"default" | "positive" | "critical" | "caution"} [helperType] - The type of helper message, determining its styling.
 * @param {string} [label] - The label text for the input field.
 * @param {Function} [onBlur] - Callback function triggered when the input loses focus.
 * @param {Function} [onChange] - Callback function triggered when the input value changes.
 * @param {Function} [onFocus] - Callback function triggered when the input gains focus.
 * @param {string} [placeholder] - Placeholder text displayed inside the input field.
 * @param {string} [type="text"] - The type of input field (e.g., "text", "password", "email").
 * @param {boolean} [isDisabled=false] - Whether the input is disabled.
 * @param {boolean} [shouldAutoFocus=false] - Whether the input should automatically focus when rendered.
 * @param {number} [maxLength] - Define a max length for the field
 * @param {string} [startAdornment] - Set adornment at start
 * @param {Object} props - Additional props passed to the input element.
 * @param {React.Ref} ref - Forwarded ref for the input component.
 */

const TextField = forwardRef(
  (
    {
      id,
      defaultValue,
      helperText,
      helperType,
      label,
      onBlur = () => {},
      onChange = () => {},
      onFocus = () => {},
      placeholder,
      type = "text",
      isDisabled = false,
      shouldAutoFocus = false,
      maxLength,
      value,
      startAdornment,
      inputProps = {
        inputClassName: "",
      },
      fieldProps = {
        labelClassName: "",
      },
    },
    ref,
  ) => {
    const fieldId = id || useId()

    const blurTimeoutRef = useRef(null)
    const [isFocused, setFocused] = useState(false)

    const _onFocus = () => {
      if (blurTimeoutRef.current) {
        clearTimeout(blurTimeoutRef.current)
      }

      setFocused(true)
      onFocus?.()
    }

    const _onBlur = () => {
      blurTimeoutRef.current = setTimeout(() => {
        setFocused(false)
        onBlur?.()
      }, 200)
    }

    const hasError = helperType === "critical"

    const inputClassNames = [
      "outline-none",
      "w-full",
      "text-harmony-text-base",
      "dark:text-harmony-text-base-dark",
      "bg-transparent",
    ]

    if (inputProps.inputClassName) {
      inputClassNames.push(inputProps.inputClassName)
    }

    return (
      <Field
        fieldId={fieldId}
        label={label}
        helperText={helperText}
        helperType={helperType}
        fieldProps={fieldProps}
      >
        <InputBase isFocused={isFocused} hasError={hasError}>
          {startAdornment && (
            <span
              className="text-nowrap pr-2.5 mr-2.5 text-harmony-text-subtle border-r-[1px] border-harmony-border-base"
              data-testid="start-adornment"
            >
              {startAdornment}
            </span>
          )}
          <input
            id={fieldId}
            ref={ref}
            type={type}
            defaultValue={defaultValue}
            className={inputClassNames.join(" ")}
            placeholder={placeholder}
            onFocus={_onFocus}
            onBlur={_onBlur}
            onChange={onChange}
            disabled={isDisabled}
            autoFocus={shouldAutoFocus}
            aria-describedby={helperText ? `${fieldId}-helper` : undefined}
            aria-invalid={hasError}
            maxLength={maxLength}
            value={value}
          />
        </InputBase>
      </Field>
    )
  },
)

TextField.displayName = "TextField"

export { TextField }
