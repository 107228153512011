import { Loader } from "@harmony"
import { useCheckout } from "modules/checkout/hooks"
import { useSharedFreedomPay, useFreedomPay } from "services/FreedomPay"
import { IFRAME_TYPES } from "services"
import { Navigate } from "react-router-dom"
import { Routes } from "modules/checkout/constants"
export function FreedomPay({ config }) {
  const { state } = useCheckout()
  const { onPaymentError, createIntent, confirmIntent } = useSharedFreedomPay()
  const { isLoading, renderIframe } = useFreedomPay({
    config,
    getIframeUrls: () => createIntent([IFRAME_TYPES.CARD]),
    onError: (err) => onPaymentError(err),
    onConfirmation: (data) => confirmIntent(data),
  })

  if (!state.userDataCardPayment?.email) {
    return <Navigate to={Routes.PaymentCardUserDetailsBill} />
  }

  if (isLoading) {
    return (
      <div className="flex justify-center w-full">
        <Loader />
      </div>
    )
  }

  return renderIframe(IFRAME_TYPES.CARD)
}
