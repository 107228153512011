import PropTypes from "prop-types"

/**
 * TextLink component for rendering accessible and stylised links.
 *
 * @param {Object} props - The props for the TextLink component.
 * @param {string} [props.className=""] - Additional CSS classes for customising the link's appearance.
 * @param {string} [props.href=""] - The URL the link points to.
 * @param {string} [props.target] - The optional target attribute for the link.
 * @param {string} [props.rel] - The optional rel attribute for the link.
 * @param {Function} [props.onClick=null] - Optional click handler for the link.
 * @param {React.ReactNode} props.children - The content to be displayed inside the link.
 */

export function TextLink({
  className = "",
  href = "",
  target,
  rel,
  onClick = null,
  children,
}) {
  const textLinkClasses = [
    "text-base",
    "text-harmony-text-base",
    "dark:text-harmony-text-base-dark",
    "underline",
    "cursor-pointer",
  ]

  if (className) {
    textLinkClasses.push(className)
  }

  if (onClick) {
    return (
      <a onClick={onClick} className={textLinkClasses.join(" ")}>
        {children}
      </a>
    )
  }

  return (
    <a
      href={href}
      target={target}
      rel={rel}
      className={textLinkClasses.join(" ")}
    >
      {children}
    </a>
  )
}

TextLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}
