import { Icon, Panel, Text } from "@harmony"
import React from "react"

const socialLinksData = [
  {
    platform: "facebook",
    key: "facebook",
    iconName: "FacebookLogo",
  },
  {
    platform: "x",
    key: "x",
    iconName: "TwitterLogo",
  },
  {
    platform: "instagram",
    key: "instagram",
    iconName: "InstagramLogo",
  },
  {
    platform: "tiktok",
    key: "tiktok",
    iconName: "TiktokLogo",
  },
]

export function SocialMedia({ social }) {
  return (
    <Panel>
      <div className="flex items-center justify-center gap-8">
        {socialLinksData
          .filter((link) => social[link.key])
          .map((link, index) => (
            <a
              key={index}
              href={social[link.key]}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
              aria-label={link.platform}
            >
              <Icon name={link.iconName} aria-hidden="true" />
            </a>
          ))}
        {social.socialId && <Text component="span">{social.socialId}</Text>}
      </div>
    </Panel>
  )
}
