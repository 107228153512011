import { Heading, Stack, Text } from "@harmony"
import { useCheckout, useNavigateExtended } from "modules/checkout/hooks"
import { ModalControls, PriceDetails } from "modules/checkout/parts"
import { PaymentStatus } from "modules/checkout/parts"
import { Stripe, FreedomPay } from "./components"
import { PAYMENT_PROVIDERS } from "modules/checkout/constants"

const getPaymentProvider = (config) => {
  const provider = config.provider
  switch (provider) {
    case PAYMENT_PROVIDERS.FREEDOMPAY:
      return (
        <div className="flex flex-col gap-4">
          <FreedomPay config={config} />
        </div>
      )
    case PAYMENT_PROVIDERS.STRIPE:
      return (
        <div className="flex flex-col gap-4 text-center">
          <Stripe />
        </div>
      )
    default:
      return null
  }
}

export function PayBill() {
  const { onEndFlow } = useCheckout()
  const navigate = useNavigateExtended()

  const { state, config } = useCheckout()

  const { share, hasSettledBill } = state

  const shouldShowPayBill = Boolean(state.paymentState)
  const shouldUnmountPaymentMethods = hasSettledBill

  return (
    <>
      <PaymentStatus paymentState={state.paymentState} />

      {!shouldUnmountPaymentMethods && (
        <div className={shouldShowPayBill ? "hidden" : "block"}>
          <ModalControls
            onNavigateBack={() => {
              navigate.previous()
            }}
            onClose={onEndFlow}
          />
          <Stack>
            <Heading level={2}>Pay the bill</Heading>

            {state.isPayingFull && (
              <Text>
                Use a wallet payment or enter your card details to settle the
                full amount.
              </Text>
            )}

            {state.isPayingSplit && (
              <Text>
                Use a wallet payment or enter your card details to settle your
                share of the bill
              </Text>
            )}

            <PriceDetails {...share} />
            {getPaymentProvider(config)}
          </Stack>
        </div>
      )}
    </>
  )
}
