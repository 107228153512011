import { Panel, PromotionalTile } from "@harmony"

export function Affiliates({ affiliates }) {
  return affiliates?.map((affiliate) => (
    <Panel background="transparent" key={affiliate.name}>
      <PromotionalTile
        title={affiliate.heading}
        body={affiliate.body}
        decoration="none"
      />
    </Panel>
  ))
}
