import { z } from "zod"
import { Service } from "./Service"

import currencies from "../../../utils/currencies.json"

function formatPrice(value, currencySymbol) {
  return `${currencySymbol}${value.toFixed(2)}`
}

const InvoiceSchema = z
  .object({
    reservationCode: z.string(),
    restaurantLocation: z.string(),
    server: z.string(),
    dateTime: z.string(),
    table: z.string(),
    receiptNum: z.string().optional().default(""),
    items: z.array(
      z.object({ name: z.string(), price: z.number(), quantity: z.number() }),
    ),
    tables: z.array(z.string()).default([]),
    firstName: z.string(),
    lastName: z.string(),
    guestEmail: z.string(),
    price: z.string(),
    currency: z.string(),
    subtotal: z.string(),
    serviceAmount: z.string(),
    tax: z.string(),
  })
  .transform(
    ({ price, currency, subtotal, serviceAmount, tax, items, ...data }) => {
      const currencySymbol = Object.values(currencies).find(
        (c) => c.code.toLowerCase() === currency.toLowerCase(),
      )?.symbol

      return {
        ...data,
        total: {
          total: price,
          subtotal: subtotal,
          tax,
          service: serviceAmount,
        },
        items: items.map((item) => ({
          ...item,
          price: formatPrice(item.price, currencySymbol),
        })),
      }
    },
  )

const ReservationSchema = z.object({
  invoiceID: z.string().optional().nullable(),
  reservationData: z.object({
    tenantId: z.string(),
    subtenantId: z.string().optional().nullable(),
  }),
})

export class BillService extends Service {
  async sendReceipt(email, invoiceId) {
    const response = await fetch(`${this.hostname}billsV2/bill-mail/`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        billId: invoiceId,
        email,
      }),
    })

    if (response.ok) {
      const data = await response.json()
      return data
    }

    const err = await response.json()
    throw new Error(err?.message || "Unexpected error")
  }

  async reportProblem(reservationId, message) {
    const response = await fetch(
      `${this.hostname}billsV2/bill-guest-problem/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          reservationID: reservationId,
          problemMessage: message,
        }),
      },
    )
    if (response.ok) {
      const data = await response.text()
      return data
    }

    const err = await response.json()
    throw new Error(err?.message || "Unexpected error")
  }

  async getByReservationId(reservationId) {
    const response = await fetch(
      `${this.hostname}billsV2/bill-info-by-reservationId/${reservationId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    )

    const data = await response.json()

    const isSuccessful = response.status === 200

    if (!isSuccessful) {
      throw new Error(data.error ?? "Unexpected error")
    }

    return ReservationSchema.parse(data?.data)
  }

  async getByInvoiceId(invoiceId) {
    const response = await fetch(
      `${this.hostname}billsV2/bill-info/${invoiceId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    )

    const data = await response.json()

    const isSuccessful = response.status === 200

    if (!isSuccessful) {
      throw new Error(data.error ?? "Unexpected error")
    }

    return InvoiceSchema.parse(data)
  }
}
