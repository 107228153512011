import { Icon } from "@harmony"

const messageTypeClasses = {
  default: ["text-harmony-text-subtle"],
  positive: ["text-harmony-positive-700"],
  information: ["text-harmony-info-700"],
  critical: ["text-harmony-critical-700"],
}

const messageTypeIconClasses = {
  default: ["fill-harmony-text-subtle"],
  positive: ["fill-harmony-positive-700"],
  information: ["fill-harmony-info-700"],
  critical: ["fill-harmony-critical-700"],
}

const messageTypeIcons = {
  default: null,
  positive: "CheckCircle",
  information: "Info",
  critical: "XCircle",
}

/**
 * Message component that displays helper text with optional icons based on message type.
 *
 * @param {string} [fieldId] - Used for aria-describedby
 * @param {"default" | "positive" | "information" | "critical"} [helperType="default"]
 *  - The type of message, which determines the styling and icon.
 * @param {string} [helperText] - The text content of the message.
 */

export function Message({ fieldId, helperType = "default", helperText }) {
  const messageClasses = [
    "mt-2",
    "text-sm",
    "flex",
    "items-center",
    "gap-2",
    "min-h-[24px]",
    ...messageTypeClasses[helperType],
  ]

  return (
    <div className={helperText && messageClasses.join(" ")}>
      {messageTypeIcons[helperType] && (
        <div className="min-w-[24px]" aria-hidden="true">
          <Icon
            name={messageTypeIcons[helperType]}
            className={messageTypeIconClasses[helperType]}
          />
        </div>
      )}
      <span id={`${fieldId}-helper`} aria-live="polite">
        {helperText}
      </span>
    </div>
  )
}
