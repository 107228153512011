import * as Icons from "@phosphor-icons/react"
import PropTypes from "prop-types"
import { iconSizes } from "./iconSizes"

/**
 * Icon component for rendering Phosphor Icons with customizable size, weight, and color.
 * @param {Object} props - Component props.
 * @param {string} props.name - Name of the Phosphor Icon to render (e.g., "ArrowRight").
 * @param {string} [props.size="24"] - Size of the icon (e.g., "24", "1.5rem").
 * @param {"thin" | "light" | "regular" | "bold" | "fill" | "duotone"} [props.weight="regular"] - Weight of the icon.
 * @param {string} [props.color="currentColor"] - Color of the icon (e.g., Tailwind class or HEX).
 * @param {string} [props.className=""] - Additional Tailwind classes for the icon.
 */
export function Icon({
  name,
  size = iconSizes.default,
  weight = "regular",
  color = "currentColor",
  className = "",
}) {
  const PhosphorIcon = Icons[name]

  if (!PhosphorIcon) {
    // eslint-disable-next-line no-console
    console.error(`Icon "${name}" does not exist in Phosphor Icons.`)
    return null
  }

  const defaultColorClasses =
    "text-harmony-icon-base dark:text-harmony-icon-base-dark"

  return (
    <PhosphorIcon
      size={size}
      weight={weight}
      color={color}
      className={`${className ? className : defaultColorClasses}`}
    />
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  weight: PropTypes.oneOf([
    "thin",
    "light",
    "regular",
    "bold",
    "fill",
    "duotone",
  ]),
  color: PropTypes.string,
  className: PropTypes.string,
}
