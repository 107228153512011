import { Elements } from "@stripe/react-stripe-js"
import getStripeInstance from "services/stripe"
import { StripeWalletPaymentForm } from "./StripeWalletPaymentForm"
import { useCheckout, useRestaurant } from "modules/checkout/hooks"

export function StripeWalletPayment({ onPaymentConfirmation, onPaymentError }) {
  const { state } = useCheckout()

  const { restaurant } = useRestaurant()

  const stripePromise = getStripeInstance(
    restaurant.payment_gateway.publishableKey,
  )

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "payment",
        currency: state.currency,
        amount: state.shareRaw.total,
        appearance: {
          variables: {
            borderRadius: "9999px",
          },
        },
      }}
    >
      <StripeWalletPaymentForm
        onPaymentConfirmation={onPaymentConfirmation}
        onPaymentError={onPaymentError}
      />
    </Elements>
  )
}
