import { AccordionProvider } from "@harmony"
import { useId } from "react"

/**
 * Renders an accordion option with a unique index and manages open/close state.
 *
 * @param {React.ReactNode} children - The content of the accordion option.
 * @param {number} itemIndex - The index of this option.
 * @param {number} openIndex - The currently open option index.
 * @param {function} toggleIndex - A function to toggle a given option by index.
 */

export function Option({ children, itemIndex, openIndex, toggleIndex }) {
  const headerId = useId()
  const bodyId = useId()

  const isOpen = openIndex === itemIndex

  const onToggle = (e) => {
    if (e.type === "click") {
      toggleIndex(itemIndex)
      return
    }

    if (e.type === "keydown") {
      if (e.key === "Enter" || e.key === " ") {
        e.preventDefault() // Prevent default behavior for Space to avoid page scrolling
        toggleIndex(itemIndex)
      }
    }
  }

  return (
    <AccordionProvider
      value={{
        headerId,
        bodyId,
        isOpen,
        onToggle,
      }}
    >
      <div className="flex flex-col text-harmony-text-base dark:text-harmony-text-base-dark">
        {children}
      </div>
    </AccordionProvider>
  )
}
