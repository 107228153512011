import {
  Accordion as BaseAccordion,
  buildAccordionHeaderProps,
  Divider,
  Panel,
} from "@harmony"

function AccordionHeader() {
  return (
    <div className="flex items-center justify-between py-2">
      <span className="text-sm text-harmony-text-subtle dark:text-harmony-text-subtle-dark">
        Powered by Zumi
      </span>
      <div
        className="cursor-pointer flex items-center gap-2"
        {...buildAccordionHeaderProps()}
      >
        <span className="text-sm text-harmony-text-base dark:text-harmony-text-base-dark">
          Show bill information
        </span>
        <BaseAccordion.Header.Icon />
      </div>
    </div>
  )
}

export function Accordion({ children }) {
  return (
    <Panel>
      <Divider />
      <BaseAccordion>
        <BaseAccordion.Option>
          <AccordionHeader />
          <BaseAccordion.Body>{children}</BaseAccordion.Body>
        </BaseAccordion.Option>
      </BaseAccordion>
      <Divider />
    </Panel>
  )
}
