import { useContext } from "react"
import { AccordionContext } from "../contexts/AccordionContext"

/**
 * A hook that returns the current accordion context, which provides
 * unique IDs for header/body elements and indicates whether
 * the accordion panel is open or closed.
 *
 * @typedef {Object} AccordionContextValue
 * @property {string} headerId - The unique ID for the accordion header.
 * @property {string} bodyId - The unique ID for the accordion body.
 * @property {boolean} isOpen - Whether the panel is currently open (`true`) or closed (`false`).
 * @property {() => void} onToggle - A function that toggles the open/close state of the panel.
 *
 * @returns {AccordionContextValue} The current context value, including header/body IDs and open/toggle state.
 *
 */

export function useAccordionContext() {
  return useContext(AccordionContext)
}
