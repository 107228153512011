import tenantConfig from "../tenantConfig.json"

export const useTenantConfig = () => {
  if (!tenantConfig) {
    // Should this be more aggressive? throw an error? log somewhere?
    // eslint-disable-next-line no-console
    console.error("No tenant config provided available")
    return null
  }

  return tenantConfig
}
