import { forwardRef } from "react"

/**
 * InputBase component that provides a styled wrapper for input elements.
 *
 * @param {boolean} [isFocused=false] - Indicates if the input is currently focused, applying focus styles.
 * @param {boolean} [hasError=false] - Indicates if the input has an error, applying error styles.
 * @param {React.ReactNode} children - The input element or components wrapped inside the base.
 * @param {React.Ref} ref - Forwarded ref for the input container.
 */

const InputBase = forwardRef(
  ({ isFocused = false, hasError = false, children }, ref) => {
    const inputBaseClasses = [
      "border",
      "px-4",
      "rounded-lg",
      "border-harmony-border-base",
      "dark:border-harmony-border-base-dark",
      "h-[48px]",
    ]

    const inputBaseInnerClasses = [
      "border",
      "border-transparent",
      "flex",
      "items-center",
      "h-full",
    ]

    if (hasError) {
      const inputBaseErrorClasses = ["border-2", "border-critical-700"]
      inputBaseClasses.push(...inputBaseErrorClasses)
      inputBaseInnerClasses.push("border-0")
    }

    if (isFocused) {
      const inputBaseFocusedClasses = ["border-2", "border-neutral-800"]
      inputBaseClasses.push(...inputBaseFocusedClasses)
      inputBaseInnerClasses.push("border-0")
    }

    return (
      <div className={inputBaseClasses.join(" ")} ref={ref}>
        <div className={inputBaseInnerClasses.join(" ")}>{children}</div>
      </div>
    )
  },
)

InputBase.displayName = "InputBase"

export { InputBase }
