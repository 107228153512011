import { useState } from "react"
import { toast } from "react-toastify"
import { Button, Panel, Heading, TextField } from "@harmony"
import { CheckWhiteIcon, SpinnerIcon } from "components/custom/Icons"
import { IsValidEmail } from "components/custom/IsValidEmail"

export function SendReceipt({ onSubmit }) {
  const [hasSent, setSent] = useState(false)
  const [email, setEmail] = useState("")
  const [isLoading, setLoading] = useState(false)

  const isValidEmail = IsValidEmail(email)
  const canSubmit = !isLoading && isValidEmail && !hasSent

  const handleSubmit = async () => {
    if (!canSubmit) {
      return
    }

    setLoading(true)

    try {
      await onSubmit(email)
      setSent(true)
    } catch (e) {
      toast.error(e.message ?? "Sending email failed")
    } finally {
      setLoading(false)
    }
  }

  return (
    <Panel>
      <div className="flex flex-col gap-4">
        <Heading level={3}>Email your receipt</Heading>
        <TextField
          label="Email address"
          type="email"
          placeholder="Enter your email address"
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          helperType={email !== "" && !isValidEmail ? "critical" : "default"}
          helperText={
            email !== "" && !isValidEmail ? "Enter a valid Email address" : ""
          }
        />
        <Button variant="solid" onClick={handleSubmit} disabled={!canSubmit}>
          {!hasSent ? (
            <span>Send</span>
          ) : (
            <span className="flex flex-row items-center gap-2">
              <CheckWhiteIcon /> Receipt Sent
            </span>
          )}

          {isLoading && <SpinnerIcon className="ml-2 inline-block" />}
        </Button>
      </div>
    </Panel>
  )
}
