import {
  useInvoice,
  useLoading,
  useNavigateExtended,
  useReservation,
} from "../hooks"

import { useEffect, useReducer } from "react"

import { CheckoutContext } from "./CheckoutContext"
import { CHECKOUT_INITIAL_STATE, CheckoutReducer } from "../reducers/reducers"
import { PAYMENT_STATE } from "modules/checkout/reducers/helpers"

import {
  getPayment,
  loadUser,
  onPayment,
  setPayFull,
  setPaySplitBy,
  setPaySplitSize,
  setPaymentState,
  setUserDataCardPayment,
  setTip,
} from "../reducers/actions"
import { ReduxDevtools } from "../reducers/helpers"
import { useSearchParams } from "react-router-dom"
import { usePostHog } from "posthog-js/react"
import { TipType } from "../api"
import { useQuery } from "@tanstack/react-query"
import merge from "lodash.merge"
import { PaymentError } from "../parts"
import { useGetPaymentProviderConfigs } from "../hooks"
const CHECKOUT_STATE_KEY_PREFIX = "CHECKOUT_STATE_"

export function useCheckoutState(reservationId) {
  const storageKey = `${CHECKOUT_STATE_KEY_PREFIX}${reservationId}`
  const initialState =
    JSON.parse(localStorage.getItem(storageKey)) || CHECKOUT_INITIAL_STATE

  const [state, dispatch] = useReducer(CheckoutReducer, initialState)

  useEffect(() => {
    if (!reservationId) {
      return
    }

    const currentStored = JSON.parse(localStorage.getItem(storageKey) || "{}")
    const nextState = merge({}, currentStored, state)
    localStorage.setItem(storageKey, JSON.stringify(nextState))
  }, [state, reservationId, storageKey])

  const getState = () => state

  // Devtools
  useEffect(() => {
    ReduxDevtools?.init(initialState)
  }, [])

  return {
    state,
    getPayment: () =>
      getPayment(dispatch, getState)({ reservationId, ...state }),
    setTip: ({ tipType, tipAmount }) =>
      setTip(
        dispatch,
        getState,
      )({ reservationId, ...state, tipType, tipAmount }),
    setPayFull: () =>
      setPayFull(
        dispatch,
        getState,
      )({
        reservationId,
        ...state,
        splitSize: 1,
        splitBy: 1,
      }),
    setPaySplitBy: ({ splitBy }) =>
      setPaySplitBy(dispatch, getState)({ reservationId, ...state, splitBy }),
    setPaySplitSize: ({ splitSize }) =>
      setPaySplitSize(
        dispatch,
        getState,
      )({ reservationId, ...state, splitSize }),
    loadUser: (user) => loadUser(dispatch, getState)(user),
    onPayment: (payment) => onPayment(dispatch, getState)(payment),
    setPaymentState: (paymentState) =>
      setPaymentState(dispatch, getState)(paymentState),
    setUserDataCardPayment: (userData) =>
      setUserDataCardPayment(dispatch, getState)(userData),
  }
}

export function CheckoutProvider({ children }) {
  const { setLoading } = useLoading()

  const { reservation } = useReservation()

  const { reservationId, tenantId, subtenantId } = reservation
  const { config, isLoading: isLoadingConfig } = useGetPaymentProviderConfigs({
    reservationId: reservation.reservationId,
  })

  const posthog = usePostHog()

  const [searchParams] = useSearchParams()
  const navigate = useNavigateExtended(reservationId)
  const { state, ...actions } = useCheckoutState(reservationId)

  const { invoice } = useInvoice()
  const shouldRefetchReservationId =
    state.paymentState !== PAYMENT_STATE.Processing &&
    state.paymentState !== PAYMENT_STATE.Failed

  const { isLoading, isPending, isError } = useQuery({
    queryKey: ["payment", reservationId],
    queryFn: async () => {
      setLoading(true)
      try {
        await actions.getPayment()
        return null
      } catch (_) {
        // We catch, rethrow and log upstream
      } finally {
        setLoading(false)
      }
    },
    ...(shouldRefetchReservationId && {
      refetchInterval: 30000,
    }),
    enabled: !!reservationId,
  })

  function onEndFlow() {
    actions.setPaySplitSize({ splitSize: 1 })

    actions.setTip({
      tipType: TipType.Fixed,
      tipAmount: "",
    })

    navigate.reset()
  }

  const guestId = searchParams.get("id")

  useEffect(() => {
    if (!guestId) {
      return
    }

    actions.loadUser({
      guestId,
      emailAddress: invoice.guestEmail || "no-email@owner.com",
      fullName: `${invoice.firstName} ${invoice.lastName}`,
      isOwner: true,
    })
  }, [guestId])

  useEffect(() => {
    posthog.identify(state.user.guestId, {
      currentUser: state.user,
      reservationId: reservationId,
      tenantId,
      subtenantId,
    })
  }, [state.user])

  if (isError) {
    return <PaymentError reservationId={reservationId} />
  }

  if (isLoading || isPending || isLoadingConfig) {
    return null
  }

  const returnUrl = `${window.location.origin}/bill-info/${reservationId}/?invited&id=${state.user.guestId}`

  return (
    <CheckoutContext.Provider
      value={{
        onEndFlow,

        state,
        config,
        ...actions,

        returnUrl,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}
