import { ButtonLink, Heading, Image, Logo } from "@harmony"
import PropTypes from "prop-types"

/**
 * Renders a promotional tile with a title, body, and optional link.
 *
 * @param {Object} props - The props for the PromotionalTile component.
 * @param {string} props.title - The title of the promotional tile.
 * @param {string} props.body - The body text of the promotional tile.
 * @param {string} props.linkText - The text of the link.
 * @param {string} props.href - The URL of the link.
 * @param {string} props.decoration - The decoration of the tile.
 * @param {string} props.imageUrl - The URL of the image.
 */
export function PromotionalTile({
  title,
  body,
  linkText,
  href,
  decoration = "none",
  imageUrl = null,
}) {
  let decorationElement

  switch (decoration) {
    case "image":
      decorationElement = <Image src={imageUrl} alt={title} />
      break
    case "logo":
      decorationElement = <Logo />
      break
    default:
      decorationElement = null
  }

  return (
    <div className="flex flex-col gap-4 items-center text-center">
      {decorationElement}
      <Heading level={2}>{title}</Heading>
      {body && (
        <span
          className="text-harmony-text-subtle dark:text-harmony-text-subtle-dark"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
      {href && (
        <ButtonLink
          href={href}
          variant="solid"
          isFullWidth={true}
          endIcon="ArrowSquareOut"
        >
          {linkText}
        </ButtonLink>
      )}
    </div>
  )
}

PromotionalTile.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  linkText: PropTypes.string,
  href: PropTypes.string,
  decoration: PropTypes.oneOf(["image", "logo", "none"]),
  imageUrl: PropTypes.string,
}
