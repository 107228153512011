import { useNavigateExtended, useCheckout } from "modules/checkout/hooks"
import { PAYMENT_STATE } from "modules/checkout/reducers/helpers"
import {
  PaymentProcessing,
  PaymentFailed,
  PaymentSuccessful,
  PaymentCancelled,
} from "./"
import { Routes } from "modules/checkout/constants"

export function PaymentStatus({ paymentState }) {
  const navigate = useNavigateExtended()
  const { state, onEndFlow } = useCheckout()

  if (paymentState === PAYMENT_STATE.Processing) {
    return <PaymentProcessing />
  }

  if (paymentState === PAYMENT_STATE.Cancelled) {
    return (
      <PaymentCancelled
        onClose={() => {
          onEndFlow()
        }}
      />
    )
  }

  if (paymentState === PAYMENT_STATE.Failed) {
    return (
      <PaymentFailed
        onClose={() => {
          onEndFlow()
        }}
      />
    )
  }

  if (paymentState === PAYMENT_STATE.Successful) {
    return (
      <PaymentSuccessful
        onClose={() => {
          if (state.hasSettledBill || !state.user.isOwner) {
            return onEndFlow()
          }

          return navigate.append(Routes.ShareBill)
        }}
      />
    )
  }

  return null
}
