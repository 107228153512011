import { Icon } from "@harmony"

export function VisualSplitIndicator({
  numberOfPaidGuests,
  splitSize,
  splitBy,
}) {
  return (
    <div className="flex flex-row gap-1 justify-center flex-wrap m-auto">
      {Array.from({ length: splitBy }).map((_, index) => {
        const isPayingFor = index < numberOfPaidGuests + splitSize
        const hasBeenSettled = index < numberOfPaidGuests

        if (hasBeenSettled) {
          return (
            <Icon
              key={`guest_${index}`}
              name="UserCircleCheck"
              size="32"
              className="fill-harmony-positive-500 dark:fill-harmony-positive-500"
            />
          )
        }

        if (isPayingFor) {
          return (
            <Icon
              key={`guest_${index}`}
              name="UserCircle"
              size="32"
              className="fill-harmony-icon-base dark:fill-harmony-icon-base-dark"
            />
          )
        }

        return (
          <Icon
            key={`guest_${index}`}
            name="UserCircle"
            size="32"
            className="fill-harmony-icon-subtle dark:fill-harmony-icon-subtle-dark"
          />
        )
      })}
    </div>
  )
}
