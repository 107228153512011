import { Icon, Panel, Text, TextLink } from "@harmony"

import { PRIVACY_URL, TERMS_URL } from "../../../../../../config/staticUrls"

export function Venue({ name, location, email, phoneNumber, vatNumber }) {
  return (
    <Panel>
      <section className="flex flex-col items-center gap-2 text-sm">
        <Text component="p" size="sm" align="center">
          {name}
          <br />
          {location}
        </Text>
        <div className="flex items-center gap-2">
          <Icon name="Envelope" aria-hidden="true" />
          <TextLink href={`mailto:${email}`} className="text-sm">
            {email}
          </TextLink>
        </div>
        <div className="flex items-center gap-2">
          <Icon name="Phone" aria-hidden="true" />
          <TextLink href={`tel:${phoneNumber}`} className="text-sm">
            {phoneNumber}
          </TextLink>
        </div>
        <div className="flex items-center gap-2">
          <TextLink
            href={TERMS_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm"
          >
            Terms &amp; conditions
          </TextLink>
          &bull;
          <TextLink
            href={PRIVACY_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm"
          >
            Privacy policy
          </TextLink>
        </div>
        <Text component="p" size="sm">
          VAT {vatNumber}
        </Text>
      </section>
    </Panel>
  )
}
