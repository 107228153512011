import { Ellipsis } from "react-css-spinners"

export function Loader({ size }) {
  return (
    <Ellipsis
      className="[--rcs-ellipsis-color:theme(colors.harmony.loader.base.bg.DEFAULT)] dark:[--rcs-ellipsis-color:theme(colors.harmony.loader.base.bg.dark)]"
      size={size}
    />
  )
}
