import { Heading, Icon, Panel } from "@harmony"
import { useState } from "react"
import { TicketItem } from "./TicketItem"

export function TicketItems({ minimumItemsShown = 3, items = [] }) {
  const [shouldShowAll, setShowAll] = useState(false)

  const shownItems = shouldShowAll ? items : items.slice(0, minimumItemsShown)
  const unShownLength = items.length - minimumItemsShown

  return (
    <Panel background="transparent">
      <Heading level={3}>Live bill</Heading>
      <div className="flex flex-col gap-2 mt-4">
        {shownItems.map((item, index) => (
          <TicketItem
            key={index}
            name={item.name}
            quantity={item.quantity}
            price={item.price}
          />
        ))}
        {items.length > minimumItemsShown && (
          <div
            onClick={() => setShowAll(!shouldShowAll)}
            className="flex items-center justify-between font-semibold cursor-pointer"
          >
            {shouldShowAll ? (
              <>
                Collapse bill <Icon name="CaretUp" />
              </>
            ) : (
              <>
                Show full bill ({unShownLength} more items){" "}
                <Icon name="CaretDown" />
              </>
            )}
          </div>
        )}
      </div>
    </Panel>
  )
}
