import { useTenantConfig } from "@hooks"
import PropTypes from "prop-types"

export function Watermark({ variant = "icon", children }) {
  const { watermark, [variant]: logoUrl } = useTenantConfig()

  if (!watermark) {
    return children
  }

  // this could be parameterized to allow for different sizes of logo if needed later
  // we can't use string interpolation inside tw classes as I think it gets parsed too early when the values aren't present
  const config = {
    "--image-url": `url(${logoUrl})`,
    "--logo-width": "480px",
    "--logo-height": "480px",
    "--logo-opacity": "10",
    "--position-right": "-160px",
    "--position-top": "0",
    "--container-width": "320px", // logo width - "right" position offset
  }

  return (
    <section className="relative overflow-hidden" style={config}>
      {/* This stops the offset background bleeding out of the overall bill */}
      <div
        className={`absolute z-0 w-[var(--container-width)] h-[var(--logo-height)] right-0 top-0 overflow-hidden`}
      >
        <section
          // This is a little hack because tailwind won't accept dynamic urls in the `bg-[]` class.
          style={{ "--image-url": `url(${logoUrl})` }}
          className={`absolute top-0 right-[var(--logo-position-right)] bg-clip-content bg-[image:var(--image-url)] opacity-10 bg-cover bg-center h-[var(--logo-height)] w-[var(--logo-width)]`}
        >
          {/* Gradient applied over the top of the image which goes from the base bg colour to transparent */}
          <div className="w-full h-full bg-gradient-to-t from-harmony-surface-base dark:from-harmony-surface-base-dark to-transparent"></div>
        </section>
      </div>
      <div className="relative z-1">{children}</div>
    </section>
  )
}

Watermark.propTypes = {
  variant: PropTypes.oneOf(["logo", "icon"]),
}
