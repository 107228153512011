/**
 * Shared Button, ButtonLink and ButtonIcon variant classes
 * TODO: We should be able to generate these classnames for each variant by concatenating the variant, tone and usage
 */

const outlineButtonVariantClasses = {
  neutral: [
    "border",
    "bg-harmony-button-outline-neutral-bg",
    "text-harmony-button-outline-neutral-fg",
    "border-harmony-button-outline-neutral-border",
    "hover:bg-harmony-button-outline-neutral-bg-hover",
    "dark:bg-harmony-button-outline-neutral-bg-dark",
    "dark:border-harmony-button-outline-neutral-border-dark",
    "dark:text-harmony-button-outline-neutral-fg-dark",
    "dark:hover:bg-harmony-button-outline-neutral-bg-hover-dark",
  ],
  brand: [
    "bg-transparent",
    "border",
    "border-brand-accent-600",
    "hover:bg-brand-accent-100",
    "text-brand-accent-600",
  ],
}

const solidButtonVariantClasses = {
  neutral: [
    "bg-harmony-button-solid-neutral-bg",
    "text-harmony-button-solid-neutral-fg",
    "hover:bg-harmony-button-solid-neutral-bg-hover",
    "dark:bg-harmony-button-solid-neutral-bg-dark",
    "dark:text-harmony-button-solid-neutral-fg-dark",
    "dark:hover:bg-harmony-button-solid-neutral-bg-hover-dark",
  ],
  brand: ["bg-brand-accent-600", "hover:bg-brand-accent-500", "text-white"],
}

const transparentButtonVariantClasses = {
  neutral: [
    "bg-harmony-button-transparent-neutral-bg",
    "text-harmony-button-transparent-neutral-fg",
    "hover:bg-harmony-button-transparent-neutral-bg-hover",
    "dark:bg-harmony-button-transparent-neutral-bg-dark",
    "dark:hover:bg-harmony-button-transparent-neutral-bg-hover-dark",
    "dark:text-harmony-button-transparent-neutral-fg-dark",
  ],
  brand: [
    "bg-transparent",
    "hover:bg-brand-accent-100",
    "text-brand-accent-600",
  ],
}

const softButtonVariantClasses = {
  neutral: [
    "bg-harmony-button-soft-neutral-bg",
    "text-harmony-button-soft-neutral-fg",
    "hover:bg-harmony-button-soft-neutral-bg-hover",
    "dark:bg-harmony-button-soft-neutral-bg-dark",
    "dark:text-harmony-button-soft-neutral-fg-dark",
    "dark:hover:bg-harmony-button-soft-neutral-bg-hover-dark",
  ],
}

export const buttonVariantClasses = {
  outline: outlineButtonVariantClasses,
  solid: solidButtonVariantClasses,
  transparent: transparentButtonVariantClasses,
  soft: softButtonVariantClasses,
}
