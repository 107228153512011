import { Icon, Panel, Alert } from "@harmony"

export function Header({
  user,
  dateTime,
  hasMadePayment,
  payments,
  isPayingSplit,
  isPayingFull,
  hasSettledBill,
}) {
  const { isOwner } = user

  const payment = payments[payments.length - 1]
  const amountOfSplitPaid = payment && payment.allocation.splitSize

  switch (true) {
    case isOwner && hasMadePayment && isPayingSplit && amountOfSplitPaid > 1:
      return (
        <Alert variant="strong" tone="positive">
          You've paid a {payment.paid} share of the bill for {amountOfSplitPaid}{" "}
          guests, thank you
        </Alert>
      )

    case isOwner && hasMadePayment && isPayingSplit:
      return (
        <Alert variant="strong" tone="positive">
          You've paid a {payment.paid} share of the bill, thank you
        </Alert>
      )

    case isOwner && ((hasMadePayment && isPayingFull) || hasSettledBill):
      return (
        <Alert variant="strong" tone="positive">
          Your bill has been paid, thank you
        </Alert>
      )

    case !isOwner && hasMadePayment && isPayingSplit && amountOfSplitPaid > 1:
      return (
        <Alert variant="strong" tone="positive">
          You've paid your {payment.paid} share of the bill for{" "}
          {amountOfSplitPaid} guests, thank you
        </Alert>
      )

    case !isOwner && hasMadePayment && isPayingSplit:
      return (
        <Alert variant="strong" tone="positive">
          You've paid your {payment.paid} share of the bill, thank you
        </Alert>
      )

    case !isOwner && ((hasMadePayment && isPayingFull) || hasSettledBill):
      return (
        <Alert variant="strong" tone="positive">
          Your bill has been paid, thank you
        </Alert>
      )

    // Catch missing states with generic messaging
    case hasSettledBill:
      return (
        <Alert variant="strong" tone="positive">
          Your bill has been paid, thank you
        </Alert>
      )
  }

  const messages = []

  if (user.isOwner) {
    messages.push(
      <p
        className="flex items-center gap-2 font-semibold"
        key="welcome-message"
      >
        <Icon name="Smiley" />
        {`Hi ${user.fullName}`}
      </p>,
    )
  }

  messages.push(
    <p className="flex items-center gap-2" key="date">
      <Icon name="CalendarDots" /> {new Date(dateTime).toDateString()}
    </p>,
  )

  return (
    <Panel background="transparent">
      <div className="flex flex-col gap-1">{messages}</div>
    </Panel>
  )
}
